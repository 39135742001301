/* storybook-check-ignore */
import { useMemo } from 'react';

import {
  Box,
  Lightbox as BricksNextLightbox,
  Button,
  FavoriteButton,
  Text,
} from '@opendoor/bricks-next';
import { formatCurrency } from '@opendoor/bricks/helpers/currencyHelpers';

import { PropertyDetailsType } from 'components/marketplace/property';

import { useObservability } from '../../../helpers/observability';
import { CONSUMER_URL } from '../../globals';

interface LightboxProps {
  handleClose: () => void;
  isOpen: boolean;
  property: PropertyDetailsType;
  selectedPhotoIndex: number;
}

export const Lightbox = ({ handleClose, isOpen, property, selectedPhotoIndex }: LightboxProps) => {
  const { trackJourneyEvent } = useObservability();
  const LightboxHeader = useMemo(() => {
    let priceToDisplay = property?.listPrice;
    if (property.displayBadge === 'ON_THE_MARKET' && property.buyDirectPrice) {
      priceToDisplay = property.buyDirectPrice;
    }
    return (
      <Box flexDirection="column" alignItems="center" flex={1}>
        <Text tag="span" typography="$labelMedium" color="$contentInversePrimary">
          {priceToDisplay ? formatCurrency(priceToDisplay) : 'Price not available'}
        </Text>
        <Text tag="span" typography="$bodyXsmall" color="$contentInversePrimary" ml="$2x">
          {property.street}
        </Text>
      </Box>
    );
  }, []);

  const LightboxFooter = useMemo(() => {
    const handleSelfTour = () => {
      window.open(`${CONSUMER_URL}/dashboard/buy/tour?id=${property.addressToken}`, '_blank');
      trackJourneyEvent('marketplaceProperty', 'tourBookingStart', {
        addressUuid: property.addressToken,
        placement: 'lightbox',
      });
    };
    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        pt="$4x"
        px="$12x"
        $smallerThanMD={{ display: 'none' }}
        width="100%"
      >
        <Box flexDirection="row" alignItems="center" flex={1}>
          <Text tag="span" typography="$labelMedium" color="$contentInversePrimary">
            {property?.buyDirectPrice
              ? formatCurrency(property.buyDirectPrice)
              : 'Price not available'}
          </Text>
          <Text tag="span" typography="$bodyMedium" color="$contentInversePrimary" ml="$2x">
            — {property.street}, {property.city}, {property.state} {property.zip}
          </Text>
        </Box>
        <Box alignSelf="flex-end" alignItems="center" justifyContent="flex-end" flexDirection="row">
          <FavoriteButton
            analyticsName="cosmos-marketplace-pdp-lightbox-favorite-property-button"
            onPress={property?.onClickReaction}
            size="medium"
            variant="inverse"
            mx="$12x"
            isFavorited={property?.isLiked ?? false}
          />
          {property.addressToken && (
            <Button
              analyticsName="cosmos-marketplace-pdp-lightbox-self-tour-button"
              label="Self-tour now"
              onPress={handleSelfTour}
              size="small"
              variant="secondaryInverse"
            />
          )}
        </Box>
      </Box>
    );
  }, [property]);

  return (
    <BricksNextLightbox
      analyticsName="cosmos-marketplace-property-photo-lightbox"
      footer={LightboxFooter}
      handleClose={handleClose}
      isOpen={isOpen}
      mobileTitle={LightboxHeader}
      photoUrls={property?.photos ?? []}
      selectedPhotoIndex={selectedPhotoIndex}
    />
  );
};

export default Lightbox;
