/* storybook-check-ignore */
import { FC } from 'react';

import { useAuth } from '@opendoor/auth-fe';
import { Box, Dialog } from '@opendoor/bricks-next';
import { ExclusiveTourTypeSelector } from '@opendoor/cinderblocks/marketplace/ExclusiveTourTypeSelector';
import { TourChoice } from '@opendoor/cinderblocks/marketplace/ExclusiveTourTypeSelector/ExclusiveTourTypeSelectorProps';

import { PropertyDetailsFragment } from '__generated__/athena';

import { getLoginRedirectUrl } from 'components/exclusives/helpers/redirectUnauthenticatedToLogin';
import { getTourRequestPath } from 'components/exclusives/tours/utils';

import { nextAvailableOpenHouse } from '../helpers';

interface TourSelectorModalProps {
  property: PropertyDetailsFragment;
  isOpen: boolean;
  onClose: () => void;
}

const TourSelectorModal: FC<TourSelectorModalProps> = ({ property, isOpen, onClose }) => {
  const { user } = useAuth();

  const nextOpenHouse = nextAvailableOpenHouse(property);

  const onSelectTour = (tour: TourChoice) => {
    if (tour.choice === 'showing') {
      const date = `${tour.date.getMonth() + 1}/${tour.date.getDate()}/${tour.date.getFullYear()}`;
      const path = getTourRequestPath(property.slug, encodeURIComponent(date));
      window.location.href = user ? path : getLoginRedirectUrl(path);
    }

    if (tour.choice === 'schedule') {
      const path = getTourRequestPath(property.slug);
      window.location.href = user ? path : getLoginRedirectUrl(path);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={onClose}
      analyticsName="cosmos-exclusives-tour-selector-modal"
      trackImpression
    >
      <Dialog.Content
        title="Tour this home"
        description="The seller currently lives in this home. Reserve a spot at the open house or schedule a tour."
      >
        <Box pt="$12x" />
        <ExclusiveTourTypeSelector
          showing={nextOpenHouse}
          onSelect={onSelectTour}
          button="primary"
        />
      </Dialog.Content>
    </Dialog>
  );
};

export default TourSelectorModal;
