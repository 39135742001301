/* storybook-check-ignore */
import { Box, Text } from '@opendoor/bricks-next';
import { Divider } from '@opendoor/bricks/core';
import { ExclusivePricePicker } from '@opendoor/cinderblocks/marketplace/ExclusivePricePicker';

import { PropertyDetailsType } from '../types';
import { ExclusiveTouringCTA } from './ExclusiveTouringCTA';

interface ExclusiveOnTheMarketCTAsProps {
  property: PropertyDetailsType;
}

export const ExclusiveOnTheMarketCTAs = ({ property }: ExclusiveOnTheMarketCTAsProps) => {
  if (!property.exclusiveOfferRangeCents) {
    return null;
  }

  const { low, okay, good, great, marketRate } = property.exclusiveOfferRangeCents;

  const prettyMarketRate = `$${(marketRate / 100).toLocaleString()}`;

  const makeOfferUrl = (price: number): string => {
    const params = new URLSearchParams();
    params.set('offerPrice', price.toString());

    let offerQuality = 'low_offer';
    if (price >= great) {
      offerQuality = 'great_offer';
    } else if (price >= good) {
      offerQuality = 'good_offer';
    } else if (price >= okay) offerQuality = 'okay_offer';

    params.set('offerQuality', offerQuality);

    return `/exclusives/homes/${property.slug}/make-offer?${params.toString()}`;
  };

  const handleStartOffer = (price: number): void => {
    window.location.href = makeOfferUrl(price);
  };

  return (
    <>
      <ExclusivePricePicker
        low={low}
        okay={okay}
        good={good}
        great={great}
        marketPrice={marketRate}
        onStartOffer={handleStartOffer}
      />
      <Box backgroundColor="$backgroundSecondary" borderRadius="$12x" p="$16x" mt="$20x">
        <Text tag="span" typography="$labelMedium" fontWeight="$medium">
          Will I pay less than a traditional purchase?
        </Text>
        <Text tag="span" typography="$bodySmall">
          We streamline the process of listing a home to help buyers like you save money. We
          estimate this home would be publicly listed at {prettyMarketRate}.
        </Text>
      </Box>
      <Divider mt={40} mb={16} />
      <ExclusiveTouringCTA property={property} />
      <Divider my={16} />
    </>
  );
};
