/* storybook-check-ignore */
import { FC } from 'react';

import { Button, Text } from '@opendoor/bricks-next';
import { Divider } from '@opendoor/bricks/core';

import { PropertyDetailsType } from '../types';

interface ExclusiveComingSoonCTAProps {
  property: PropertyDetailsType;
}

export const ExclusiveComingSoonCTA: FC<ExclusiveComingSoonCTAProps> = ({ property }) => {
  const range = property.exclusivePrelimOfferRangeCents;

  let price = '';
  if (range) {
    if (range.upper) {
      price = `$${range.lower / 100_000}K-$${range.upper / 100_000}K`;
    } else {
      price = `$${range.lower / 100_000}K`;
    }
  }

  return (
    <>
      {range && (
        <>
          <Text tag="span" typography="$bodySmall" pb="$2x">
            Estimated price{range.upper && ' range'}
          </Text>
          <Text tag="span" typography="$subheaderLarge" fontWeight="$medium" pb="$12x">
            {price}
          </Text>
          <Text tag="span" typography="$bodySmall">
            We’re finalizing the asking price for this home.
          </Text>
          <Text tag="span" typography="$bodySmall" pb="$12x">
            Save it to your favorites to see when it’s available.
          </Text>
        </>
      )}
      <Button
        size="medium"
        analyticsName="cosmos-pdp-coming-soon-favorite"
        label={property.isLiked ? 'Remove from Favorites' : 'Save to Favorites'}
        variant="secondary"
        mb="$6x"
        onPress={property.onClickReaction}
      />
      <Text tag="span" typography="$bodySmall" alignSelf="center" color="$contentTertiary">
        We’ll pay your agent’s fee — up to 3%
      </Text>
      <Divider mt={48} mb={16} />
    </>
  );
};
