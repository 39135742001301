import { useEffect } from 'react';

import { DEPLOY_ENV } from '../../globals';
import { AccountProps } from '../../shared/AccountConnector';
import { ExclusivesRoutes } from '../constants/routes';

export default ({ account, redirectUrl }: { account: AccountProps; redirectUrl: string }) => {
  const { loggedIn, infoIsLoading } = account;
  // redirecting the user to the login page if they are not authenticated
  useEffect(() => {
    if (!infoIsLoading && !loggedIn && DEPLOY_ENV !== 'development') {
      window.location.assign(`/exclusives/login?redirectTo=${encodeURIComponent(redirectUrl)}`);
    }
  }, [infoIsLoading, loggedIn]);

  return null;
};

export const getLoginRedirectUrl = (pathname: string) => {
  return `${ExclusivesRoutes.LOGIN}?redirectTo=${encodeURIComponent(pathname)}`;
};
