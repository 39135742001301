/* storybook-check-ignore */
import { FC, useState } from 'react';

import { CONSUMER_URL } from 'components/globals';
import { useTrackProperty } from 'components/marketplace/helpers';

import { useObservability } from 'helpers/observability';

import CancelTourModal from './PropertySection/CancelTourModal';
import { FloatingCTA } from './PropertySection/FloatingCta';
import TourSelectorModal from './PropertySection/TourSelectorModal';
import { TourWithAgentModal } from './PropertySection/TourWithAgentModal';
import { PropertyDetailsType } from './types';

type CtaKind = 'selfTour' | 'scheduleTour' | 'cancelTour' | 'tourWithAgent';

const ACTION_TEXT: Record<CtaKind, string> = {
  selfTour: 'Self-tour now',
  scheduleTour: 'Schedule tour',
  cancelTour: 'Cancel tour',
  tourWithAgent: 'Tour with agent',
};

interface FooterCTAProps {
  kind: CtaKind;
  property: PropertyDetailsType;
  isExclusive?: boolean;
}

export const FooterCTA: FC<FooterCTAProps> = ({ kind, property, isExclusive }) => {
  const { trackProperty } = useTrackProperty();
  const { trackJourneyEvent } = useObservability();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCtaClick = () => {
    if (kind === 'selfTour') {
      trackProperty('property-self-tour', 'marketplace-pdp-footer-cta');
      trackJourneyEvent('marketplaceProperty', 'tourBookingStart', {
        addressUuid: property.addressToken,
        placement: 'footer',
      });

      window.open(
        `${CONSUMER_URL}/dashboard/buy/tour?id=${property.addressToken}`,
        '_blank',
        'noopener',
      );
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <FloatingCTA
        ctaText={ACTION_TEXT[kind]}
        onClickCta={handleCtaClick}
        isExclusive={isExclusive ?? false}
        isLiked={property.isLiked}
        onClickReaction={property.onClickReaction}
        variant={kind === 'tourWithAgent' ? 'secondary' : 'primary'}
      />
      {kind === 'scheduleTour' && (
        <TourSelectorModal property={property} isOpen={isDialogOpen} onClose={handleClose} />
      )}
      {kind === 'cancelTour' && <CancelTourModal isOpen={isDialogOpen} onClose={handleClose} />}
      {kind === 'tourWithAgent' && (
        <TourWithAgentModal property={property} isOpen={isDialogOpen} onClose={handleClose} />
      )}
    </>
  );
};
