/* storybook-check-ignore */
import React, { useMemo } from 'react';

import { Accordion, Box, Text } from '@opendoor/bricks-next';
import { Divider } from '@opendoor/bricks/core';

interface SchoolsProps {
  disclaimer?: string | null;
  schools?: Array<{
    id?: string | null;
    name?: string | null;
    rating?: number | null;
  }> | null;
  schoolDistricts?: string[] | null;
  summary?: string | null;
}

export const Schools: React.FC<SchoolsProps> = ({
  disclaimer,
  schools,
  schoolDistricts,
  summary,
}) => {
  const elementarySchoolDistrict = schoolDistricts?.filter(
    (district) => !district.toLowerCase().includes('high'),
  )[0];
  const highSchoolDistrict =
    schoolDistricts?.filter((district) => district.toLowerCase().includes('high'))[0] ??
    elementarySchoolDistrict;
  const description = useMemo(
    () => (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - open attribute is not in the Box component
      <Box>
        {summary && (
          <Text tag="p" typography="$bodySmall" color="$contentPrimary" mb={24}>
            {summary}
          </Text>
        )}
        <Box flexDirection="column">
          {!!schoolDistricts?.length && (
            <Text tag="span" typography="$labelXsmall" color="$contentSecondary" my={12}>
              School districts
            </Text>
          )}
          {elementarySchoolDistrict && (
            <>
              <Box flexDirection="row" justifyContent="space-between">
                <Text tag="p" typography="$bodySmall" color="$contentPrimary">
                  Elementary
                </Text>
                <Text tag="p" typography="$labelSmall" color="$contentPrimary">
                  {elementarySchoolDistrict}
                </Text>
              </Box>
              <Divider my={5} />
            </>
          )}
          {highSchoolDistrict && (
            <>
              <Box flexDirection="row" justifyContent="space-between">
                <Text tag="p" typography="$bodySmall" color="$contentPrimary">
                  High School
                </Text>
                <Text tag="p" typography="$labelSmall" color="$contentPrimary">
                  {highSchoolDistrict}
                </Text>
              </Box>
              <Divider my={5} />
            </>
          )}
          {schools?.length && (
            <Text tag="span" typography="$labelXsmall" color="$contentSecondary" my={12}>
              Schools
            </Text>
          )}
          {schools?.map((school, index) => (
            <Box key={school.id}>
              <Box
                flexDirection="row"
                justifyContent="space-between"
                mb={index === schools.length - 1 ? 24 : 0}
              >
                <Text
                  tag="p"
                  typography="$bodySmall"
                  color="$contentPrimary"
                  textTransform="capitalize"
                >
                  {school?.name?.toLowerCase()}
                </Text>
                <Text tag="p" typography="$labelSmall" color="$contentPrimary">
                  {school?.rating ? `${school.rating} / 10` : 'N/A'}
                </Text>
              </Box>
              {index !== schools.length - 1 && <Divider my={5} />}
            </Box>
          ))}
        </Box>
        {disclaimer && (
          <Box>
            <Text tag="p" typography="$labelXsmall" color="$contentStateInactivePrimary">
              {disclaimer}
            </Text>
          </Box>
        )}
      </Box>
    ),
    [],
  );

  return (
    <>
      <Box $smallerThanLG={{ display: 'none' }} data-testid="marketplace-schools-accordion-desktop">
        <Accordion
          items={[
            {
              title: 'Schools',
              description,
            },
          ]}
          variant="medium"
          size="xlarge"
          isFullWidth
        />
      </Box>
      <Box $largerThanMD={{ display: 'none' }} data-testid="marketplace-schools-accordion-mobile">
        <Accordion
          items={[
            {
              title: 'Schools',
              description,
            },
          ]}
          variant="medium"
          size="large"
          isFullWidth
        />
      </Box>
    </>
  );
};
