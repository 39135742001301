import { ListingWithComputedProperties } from 'declarations/exclusives/listing';

export enum TourTimeSlotState {
  Proposed = 'PROPOSED',
  Rejected = 'REJECTED',
  Accepted = 'ACCEPTED',
}

export type TourTimeSlot = {
  state: TourTimeSlotState;
  startTime: Date;
  endTime: Date;
  isPreferred?: boolean;
};

export type OpenHouseTimeSlot = TourTimeSlot & {
  openHouseUuid: string;
  showingId?: string;
};

export enum ShowingType {
  WalkIn = 'SHOWING_WALK_IN',
  Request = 'SHOWING_REQUEST',
  RSVP = 'SHOWING_RSVP',
}

export enum SelfTourType {
  Instant = 'SELF_TOUR_INSTANT',
  Scheduled = 'SELF_TOUR_SCHEDULED',
  ShowingTimeScheduled = 'SHOWINGTIME_SCHEDULED',
}

export enum TourTimeSlotProposer {
  TouringCustomer = 'TOURING_CUSTOMER',
  Seller = 'SELLER',
  Unknown = 'UNKNOWN_PROPOSER',
}

export enum TourRequestState {
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  Terminated = 'TERMINATED',
}

export enum TourAgentStatus {
  SelfTour = 'buyer is touring by themselves',
  WithAgent = 'buyer is touring with an agent',
}

export enum ShowingAccess {
  Unknown = 'SHOWING_ACCESS_UNKNOWN',
  Public = 'SHOWING_ACCESS_PUBLIC',
  Private = 'SHOWING_ACCESS_PRIVATE',
}

export type PreReqDetails = {
  homeInterestNotes?: TourAgentStatus | string;
};

export type Tour = {
  // Making id optional until new touring platform is fully integrated.
  // Current backend does not return the tour id.
  id?: string;
  listing: ListingWithComputedProperties;
  customerUuid: string | null;
  openHouseUuid?: string;
  showingId?: string;
  state: TourRequestState;
  terminationReason?: string;
  type: ShowingType | SelfTourType;
  tourTimeSlots: TourTimeSlot[];
  preReqDetails?: PreReqDetails;
};

export enum PropertyAccess {
  SelfTouring = 'SELF_TOURING',
  ShowingRequired = 'SHOWING_REQUIRED',
}

export enum TourAvailability {
  Available = 'AVAILABLE',
  Preferred = 'PREFERRED',
  AutoAccept = 'AUTO_ACCEPT',
  UnknownTourAvailability = 'UNKNOWN_TOUR_AVAILABILITY',
}

export type TourAvailabilityInterval = {
  start: {
    hour: number;
    minute: number;
  };
  end: {
    hour: number;
    minute: number;
  };
  availability: TourAvailability;
};

export type TouringCalendarDay = {
  day: {
    year: number;
    month: number;
    day: number;
  };
  slots: TourAvailabilityInterval[];
};

export type TouringCalendar = {
  days: TouringCalendarDay[];
  timezone?: string;
};

export type Showing = {
  id: string;
  showingAccess: ShowingAccess;
  startTime: Date;
  endTime: Date;
};
