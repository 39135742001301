/* storybook-check-ignore */
import { useAuth } from '@opendoor/auth-fe';
import { Accordion, Box, Button, Text } from '@opendoor/bricks-next';
import { Loader } from '@opendoor/bricks/core';
import { ExclusiveTourTypeSelector } from '@opendoor/cinderblocks/marketplace/ExclusiveTourTypeSelector';
import { TourChoice } from '@opendoor/cinderblocks/marketplace/ExclusiveTourTypeSelector/ExclusiveTourTypeSelectorProps';

import {
  PropertyDetailsFragment,
  TourRequestFragment,
  OdProtosTouringData_TourRequest_State as TourRequestState,
  OdProtosTouringData_TourRequest_TourType as TourType,
} from '__generated__/athena';

import { getLoginRedirectUrl } from 'components/exclusives/helpers/redirectUnauthenticatedToLogin';
import { getTourRequestPath } from 'components/exclusives/tours/utils';

import { useCustomerToursContext } from '../context/customerToursContext';
import { formatPrettyTimeRange, nextAvailableOpenHouse } from '../helpers';

interface ExclusiveTouringCTAProps {
  property: PropertyDetailsFragment;
}

type TOURING_STATE = 'noTours' | 'scheduledOpenHouse' | 'scheduledTour' | 'toured';

const OPEN_HOUSE_TYPES: TourType[] = ['SHOWING_RSVP', 'SHOWING_WALK_IN'];
const TOUR_TYPES: TourType[] = ['SELF_TOUR_INSTANT', 'SELF_TOUR_SCHEDULED', 'SHOWING_REQUEST'];
const TOUR_STATE_PENDING: TourRequestState[] = ['PENDING', 'PENDING_VERIFICATION', 'SCHEDULED'];

const formatOpenHouseTime = (showing: TourRequestFragment | undefined): string | undefined => {
  if (!showing) {
    return undefined;
  }

  if (showing.tourTimeSlots.length < 1) {
    return undefined;
  }

  const timeSlot = showing.tourTimeSlots[0];

  const start = new Date(timeSlot.start);
  const end = new Date(timeSlot.end);

  return formatPrettyTimeRange(start, end);
};

export const ExclusiveTouringCTA = ({ property }: ExclusiveTouringCTAProps) => {
  const { user } = useAuth();

  const { tours, loading, cancelling, cancelTour } = useCustomerToursContext();

  const nextOpenHouse = nextAvailableOpenHouse(property);

  const scheduledOpenHouse = tours?.find(
    (tour) => TOUR_STATE_PENDING.includes(tour.state) && OPEN_HOUSE_TYPES.includes(tour.tourType),
  );

  const scheduledOpenHouseTime = formatOpenHouseTime(scheduledOpenHouse);

  const scheduledTour = tours?.find(
    (tour) => TOUR_STATE_PENDING.includes(tour.state) && TOUR_TYPES.includes(tour.tourType),
  );

  let tourState: TOURING_STATE = 'noTours';

  // Check for cancellable tours first to show the UX for cancellation
  if (scheduledOpenHouse) {
    tourState = 'scheduledOpenHouse';
  } else if (scheduledTour) {
    tourState = 'scheduledTour';
  } else if (tours?.find((tour) => tour.state === 'COMPLETED')) {
    // Then check if we need to minimize the touring accordion
    tourState = 'toured';
  }

  const onSelectTour = (tour: TourChoice) => {
    if (tour.choice === 'showing') {
      const date = `${tour.date.getMonth() + 1}/${tour.date.getDate()}/${tour.date.getFullYear()}`;
      const path = getTourRequestPath(property.slug, encodeURIComponent(date));
      window.location.href = user ? path : getLoginRedirectUrl(path);
    }

    if (tour.choice === 'schedule') {
      const path = getTourRequestPath(property.slug);
      window.location.href = user ? path : getLoginRedirectUrl(path);
    }
  };

  const onCancelShowing = () => {
    if (scheduledOpenHouse) {
      cancelTour(scheduledOpenHouse.id);
    }
  };

  const onCancelTour = () => {
    if (scheduledTour) {
      cancelTour(scheduledTour.id);
    }
  };

  return loading ? (
    <Box alignItems="center">
      <Loader />
    </Box>
  ) : (
    <>
      {(tourState === 'noTours' || tourState === 'toured') && (
        <Accordion
          items={[
            {
              title: 'Tour this home',
              description: (
                <Box>
                  <Text tag="span" typography="$bodySmall" mb="$12x">
                    This home is occupied by a seller and requires prior approval to tour.
                  </Text>
                  <ExclusiveTourTypeSelector showing={nextOpenHouse} onSelect={onSelectTour} />
                </Box>
              ),
            },
          ]}
          variant="medium"
          size="large"
          defaultOpen={tourState === 'noTours'}
          isFullWidth
        />
      )}
      {tourState === 'scheduledOpenHouse' && (
        <Box mt={24} mb={34}>
          <Text tag="span" typography="$subheaderXsmall">
            Scheduled tour
          </Text>
          <Box
            mt="$12x"
            p="$12x"
            borderColor="$borderPrimary"
            borderWidth="$0dot5x"
            borderRadius="$12x"
          >
            {scheduledOpenHouseTime && (
              <Text tag="span" typography="$labelXlarge" mb="$6x">
                {scheduledOpenHouseTime}
              </Text>
            )}
            <Text tag="span" typography="$bodySmall">
              We&rsquo;ll text and email you a confirmation. A member of our team will meet you at
              the door to unlock it for your tour.
            </Text>
            <Button
              size="medium"
              analyticsName="cosmos-exclusives-cancel-showing"
              variant="secondary"
              label="Cancel tour"
              mt="$12x"
              onPress={onCancelShowing}
              loading={cancelling}
            />
          </Box>
        </Box>
      )}
      {tourState === 'scheduledTour' && (
        <Box mt={24} mb={34}>
          <Text tag="span" typography="$subheaderXsmall">
            Tour requested
          </Text>
          <Text tag="span" typography="$bodySmall" mt="$4x">
            We&rsquo;re working on scheduling your tour. This typically takes 24 hours or less.
          </Text>
          <Button
            size="medium"
            analyticsName="cosmos-exclusives-cancel-tour"
            variant="secondary"
            label="Cancel tour"
            mt="$12x"
            onPress={onCancelTour}
            loading={cancelling}
          />
        </Box>
      )}
    </>
  );
};
