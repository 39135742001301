import { globalObservability } from '@opendoor/observability/slim';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

import {
  GetViewingAppointmentsDataQuery,
  OdProtosTouringData_TourRequest_BuyersAgentStatus,
  OdProtosTouringData_TourRequestInput,
} from '__generated__/athena';

import { doGet, getAthenaClient } from 'components/api';

import { ListingWithComputedProperties } from 'declarations/exclusives/listing';

import { ExclusiveTourRequest } from '../HeroPriceAndBenefitsSection/RequestATour/util';
import { FIRST_BOOKABLE_SHOWING_SLOT_BUFFER_TIME } from './constants';
import {
  SelfTourType,
  ShowingType,
  Tour,
  TourAgentStatus,
  TourRequestState,
  TourTimeSlot,
  TourTimeSlotProposer,
} from './types';

const Sentry = globalObservability.getSentryClient();

// e.g. Mon, Jan 1
export const getTimeSlotDateLabel = (timeSlot: TourTimeSlot) =>
  `${timeSlot.startTime.toLocaleDateString('en-US', {
    weekday: 'short',
  })}, ${timeSlot.startTime.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })}`;

// e.g. 10am - 11am
export const getTimeSlotTimeLabel = (timeSlot: TourTimeSlot, timeZone?: string) =>
  `${timeSlot.startTime
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      timeZone,
    })
    .toLocaleLowerCase()
    .replace(' ', '')} - ${timeSlot.endTime
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      timeZone,
    })
    .toLocaleLowerCase()
    .replace(' ', '')}`;

// e.g. 10:00am
export const getSelfTourTimeSlotTimeLabel = (timeSlot: TourTimeSlot, timeZone?: string) =>
  timeSlot.startTime
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: '2-digit',
      timeZone,
    })
    .toLocaleLowerCase()
    .replace(' ', '');

// e.g. 10:00am - 11:30am
export const getSelfTourTimeSlotDurationLabel = (timeSlot: TourTimeSlot, timeZone?: string) =>
  `${timeSlot.startTime
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: '2-digit',
      timeZone,
    })
    .toLocaleLowerCase()
    .replace(' ', '')} - ${timeSlot.endTime
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: '2-digit',
      timeZone,
    })
    .toLocaleLowerCase()
    .replace(' ', '')}`;

export const getTourRequestAPIPath = (listingSlug: string) =>
  `exclusives/${listingSlug}/tour-request`;

export const getOpenHouseReservationsRequestAPIPath = (listingSlug: string) =>
  `exclusives/${listingSlug}/open-house-reservations`;

export const getTourRequestPath = (listingSlug: string, selectedWeekday?: string) =>
  `/exclusives/homes/${listingSlug}/tour-request${
    selectedWeekday ? `?selectedWeekday=${selectedWeekday}` : ''
  }`;

export const getTourSchedulePath = (listingSlug: string, selectedWeekday?: string) =>
  `/exclusives/homes/${listingSlug}/schedule${
    selectedWeekday ? `?selectedWeekday=${selectedWeekday}` : ''
  }`;

// Temp solution to block TRs for 2024 US holidays.
export const is2024USHoliday = (date: Date) => {
  const month = date.getMonth();
  const day = date.getDate();

  // Thanksgiving Day
  if (month === 10 && day === 28) {
    return true;
  }

  // Christmas Day
  if (month === 11 && day === 25) {
    return true;
  }

  // New Year's Day
  if (month === 0 && day === 1) {
    return true;
  }

  return false;
};

export const hasTimeToScheduleTourTimeSlot = (slot: TourTimeSlot, timezone?: string): boolean => {
  const currentHour = DateTime.now().setZone(timezone).hour;

  // If it's before buisness hours (8am) listing local time, add the buffer
  // time for the day to give ops/sellers time to coordinate the tour request
  if (currentHour < 8) {
    const firstBookableTimeSlot = DateTime.now()
      .setZone(timezone)
      .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
      .plus(FIRST_BOOKABLE_SHOWING_SLOT_BUFFER_TIME);

    return DateTime.fromJSDate(slot.startTime) >= firstBookableTimeSlot;
  }

  // If it's after buisness hours (5pm) listing local time, add the buffer
  // time for the next day to give ops/sellers time to coordinate the tour request
  // the following morning
  if (currentHour >= 17) {
    const tomorrowFirstBookableTimeSlot = DateTime.now()
      .setZone(timezone)
      .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
      .plus({ days: 1 })
      .plus(FIRST_BOOKABLE_SHOWING_SLOT_BUFFER_TIME);

    return DateTime.fromJSDate(slot.startTime) >= tomorrowFirstBookableTimeSlot;
  }

  return (
    DateTime.fromJSDate(slot.startTime) >=
    DateTime.now().plus(FIRST_BOOKABLE_SHOWING_SLOT_BUFFER_TIME)
  );
};

export const hasTimeToScheduleTour = (tour: Pick<Tour, 'tourTimeSlots' | 'listing'>): boolean => {
  return !tour.tourTimeSlots.some(
    (slot) => !hasTimeToScheduleTourTimeSlot(slot, tour.listing.timezone),
  );
};

export const fetchExisitingTourRequest = async (
  listingSlug: string,
): Promise<ExclusiveTourRequest | null> => {
  const resp = await doGet(`/api/v1/${getTourRequestAPIPath(listingSlug)}`, { retries: 0 });
  const body = resp.status == 204 ? null : await resp.json();

  if (!resp?.ok) {
    throw new Error(resp.statusText);
  }

  return isEmpty(body) ? null : body;
};

// Note: The open-house-reservations return all reserved open houses without filtering
// them by status, we should verify and filter the returned array with the
// listing.exclusive_listing_info.ongoing_and_upcoming_open_houses
// to achieve the ongoing and upcoming open houses reservation.
//
// This is not ideal but we will soon be switching to the new touring platform where
// the filtering will be handled on the backend so we're not updating this for now.
export const fetchExistingRsvps = async (
  listingSlug: string,
): Promise<{ open_house_uuid: string }[] | null> => {
  const resp = await doGet(`/api/v1/${getOpenHouseReservationsRequestAPIPath(listingSlug)}`, {
    retries: 0,
  });

  const body = resp.status == 204 ? null : await resp.json();

  if (!resp?.ok) {
    throw new Error(resp.statusText);
  }

  return isEmpty(body) ? null : body;
};

export const fetchExisitingTourRequestsAndRsvps = async (
  listing: ListingWithComputedProperties,
) => {
  const [tourRequest, allRsvps] = await Promise.all([
    fetchExisitingTourRequest(listing.slug),
    fetchExistingRsvps(listing.slug),
  ]);

  const ongoingOrUpcomingOpenHouseUuidsSet = new Set(
    listing.exclusive_listing_info.ongoing_and_upcoming_open_houses?.map(({ uuid }) => uuid),
  );
  const filteredRsvps = allRsvps?.filter(({ open_house_uuid }) =>
    ongoingOrUpcomingOpenHouseUuidsSet.has(open_house_uuid),
  );

  return {
    tourRequest,
    rsvps: filteredRsvps || [],
  };
};

export const createTour = async (
  tour: Partial<Tour> & {
    listing: ListingWithComputedProperties;
    tourTimeSlots: TourTimeSlot[];
  },
): Promise<Partial<Tour>> => {
  if (tour.type === SelfTourType.Scheduled) {
    if (!tour.customerUuid) {
      throw new Error('Error reserving self-tour: Must provide customer UUID');
    }

    return await createSelfTourAppointment(tour);
  }

  let buyersAgentStatus =
    'UNKNOWN_BUYERS_AGENT_STATUS' as OdProtosTouringData_TourRequest_BuyersAgentStatus;
  if (tour.preReqDetails?.homeInterestNotes === TourAgentStatus.WithAgent) {
    buyersAgentStatus = 'BUYERS_AGENT_ATTENDING';
  } else if (tour.preReqDetails?.homeInterestNotes === TourAgentStatus.SelfTour) {
    buyersAgentStatus = 'BUYERS_AGENT_NOT_ATTENDING';
  }

  try {
    const tourRequestInput: OdProtosTouringData_TourRequestInput = {
      addressId: tour.listing.address.address_token,
      customerId: tour.customerUuid,
      tourTimeSlots: tour.tourTimeSlots.map((slot) => ({
        start: slot.startTime,
        end: slot.endTime,
        state: slot.state,
        proposer: TourTimeSlotProposer.TouringCustomer,
      })),
      tourType: tour.showingId ? ShowingType.RSVP : ShowingType.Request,
      showingId: tour.showingId,
      buyersAgentStatus,
    };

    await getAthenaClient().CreateTourRequest({
      tourRequest: tourRequestInput,
    });
  } catch (err) {
    // Raise error to Sentry but don't throw during the transition period
    Sentry.captureException?.(err);
  }

  // This tour object is being constructed on the frontend
  // for right now but eventually this will be the shape of
  // the data returned from the new touring platform. It's
  // temporarily being constructed here to make the transition
  // easier when the new platform is ready.
  const newTour: Partial<Tour> = { ...tour };
  if (tour.showingId) {
    newTour.state = TourRequestState.Scheduled;
    newTour.type = ShowingType.RSVP;
  } else {
    newTour.state = TourRequestState.Pending;
    newTour.type = ShowingType.Request;
  }

  return newTour;
};

export const createSelfTourAppointment = async (
  tour: Partial<Tour> & {
    listing: ListingWithComputedProperties;
    tourTimeSlots: TourTimeSlot[];
  },
): Promise<Partial<Tour>> => {
  if (!tour.customerUuid) {
    throw new Error('Error reserving self-tour: Must provide customer UUID');
  }

  try {
    const appointmentDate = DateTime.fromJSDate(tour.tourTimeSlots[0].startTime)
      .setZone(tour.listing.timezone)
      .toFormat('yyyy-MM-dd');
    const appointmentTime = DateTime.fromJSDate(tour.tourTimeSlots[0].startTime)
      .setZone(tour.listing.timezone)
      .toFormat('HH:mm');

    await getAthenaClient().CreateViewingAppointment({
      listingId: tour.listing.id,
      customerUUID: tour.customerUuid,
      appointmentDate,
      appointmentTime,
    });
  } catch (err) {
    throw new Error('Error reserving self-tour', { cause: err });
  }

  return { ...tour, state: TourRequestState.Scheduled };
};

export const saveTourDataToLS = (listingId: number, tourData?: Partial<Tour>) => {
  if (!tourData) return;

  localStorage.setItem(`@cosmos/exclusives/tour-request/${listingId}`, JSON.stringify(tourData));
};

export const saveShouldOpenSelfTourModalToLS = (listingId: number) => {
  localStorage.setItem(
    `@cosmos/exclusives/open-self-tour-modal/${listingId}`,
    JSON.stringify(true),
  );
};

export const getOngoingAndUpcomingViewingAppointments = (
  viewingAppointmentsByCustomer: GetViewingAppointmentsDataQuery['web']['viewingAppointmentsByCustomer'],
) => {
  const now = DateTime.now();
  return viewingAppointmentsByCustomer?.filter((viewingAppointment) => {
    const endTime = DateTime.fromISO(viewingAppointment?.end_time);

    return now < endTime;
  });
};
