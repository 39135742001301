/* storybook-check-ignore */
import React, { Fragment, useMemo } from 'react';

import { Accordion, Box, Text } from '@opendoor/bricks-next';
import { Divider } from '@opendoor/bricks/core';
import { formatCurrency } from '@opendoor/bricks/helpers/currencyHelpers';

import { ListingHistoryState } from '__generated__/athena';

const PRICE_DENOMINATOR = 100;

interface MarketplaceListingHistory {
  state?: ListingHistoryState | null;
  timestamp?: any | null;
  mls?: string | null;
  originalListPriceCents?: number | null;
  soldPriceCents?: number | null;
  currentListPriceCents?: number | null;
}

interface PriceHistoryProps {
  listingHistory: MarketplaceListingHistory[];
}

export const PriceHistory: React.FC<PriceHistoryProps> = ({ listingHistory }) => {
  // Dedupe by date after cleaning up timestamp format
  const listingHistoryByDateMap: Record<string, MarketplaceListingHistory> =
    listingHistory?.reduce(
      (map: Record<string, MarketplaceListingHistory>, history: MarketplaceListingHistory) => {
        // ex: January 10, 2023
        const date = new Date(history.timestamp).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        if (!map[date]) {
          map[date] = { ...history, timestamp: date };
        }

        return map;
      },
      {},
    ) || {};

  // Filter out events without any pricing data, and any marketplace specific events
  const pricingEvents = Object.values(listingHistoryByDateMap)
    .filter(
      (history) =>
        history.state !== 'PRIVATELY_LISTED' && !(history.state === 'SALE_PENDING' && !history.mls),
    )
    .filter(
      (history) =>
        history?.soldPriceCents ||
        history?.currentListPriceCents ||
        history?.originalListPriceCents,
    );
  const description = useMemo(() => {
    const rows = Object.values(pricingEvents)?.map((history: MarketplaceListingHistory, index) => {
      let price =
        (history?.currentListPriceCents || history?.originalListPriceCents || 0) /
        PRICE_DENOMINATOR;
      const state = history.state as ListingHistoryState;
      if (state === 'SOLD' || state === 'OFF_MARKET') {
        price =
          (history?.soldPriceCents || history?.currentListPriceCents || 0) / PRICE_DENOMINATOR;
      }

      return (
        <Fragment key={history.timestamp}>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb={index === pricingEvents.length - 1 ? 24 : 0}
          >
            <Box flexDirection="column">
              <Text tag="p" typography="$bodySmall" color="$contentPrimary">
                {history.timestamp}
              </Text>
              <Text
                tag="span"
                typography="$bodyXsmall"
                color="$contentSecondary"
                textTransform="capitalize"
              >
                {state?.split('_').join(' ').toLowerCase()}
                {history.mls && ` · ${history.mls}`}
              </Text>
            </Box>
            <Text tag="strong" typography="$labelSmall" color="$contentPrimary">
              {formatCurrency(price)}
            </Text>
          </Box>
          {index !== pricingEvents.length - 1 && <Divider my={5} />}
        </Fragment>
      );
    });

    if (!rows.length) {
      return 'No price history';
    }

    return rows;
  }, [pricingEvents]);

  return (
    <>
      <Box
        $smallerThanLG={{ display: 'none' }}
        data-testid="marketplace-price-history-accordion-desktop"
      >
        <Accordion
          items={[
            {
              title: 'Price history',
              description,
            },
          ]}
          variant="medium"
          size="xlarge"
          isFullWidth
        />
      </Box>
      <Box
        $largerThanMD={{ display: 'none' }}
        data-testid="marketplace-price-history-accordion-mobile"
      >
        <Accordion
          items={[
            {
              title: 'Price history',
              description,
            },
          ]}
          variant="medium"
          size="large"
          isFullWidth
        />
      </Box>
    </>
  );
};
