/* storybook-check-ignore */
import { Divider } from '@opendoor/bricks/core';

import { PropertyDetailsType } from '../types';
import { ExclusiveComingSoonCTA } from './ExclusiveComingSoonCta';
import { ExclusiveOnTheMarketCTAs } from './ExclusiveOnTheMarketCtas';

interface ExclusiveCTAsProps {
  property: PropertyDetailsType;
}

export const ExclusiveCTAs = ({ property }: ExclusiveCTAsProps) => {
  if (property.displayBadge !== 'TRADITIONAL') {
    return null;
  }

  if (property.listingState === 'IN_CONTRACT') {
    return <Divider mt={48} mb={16} />;
  }

  return (
    <>
      <Divider my={40} />
      {property.listingState === 'AVAILABLE_SOON' && <ExclusiveComingSoonCTA property={property} />}
      {property.listingState === 'ON_THE_MARKET' && (
        <ExclusiveOnTheMarketCTAs property={property} />
      )}
    </>
  );
};
