/* storybook-check-ignore */
import { Box } from '@opendoor/bricks-next';
import { Divider } from '@opendoor/bricks/core';

import { PropertyDetailsFragment } from '__generated__/athena';

import { HomeDetails } from '../HomeDetails';
import { PriceHistory } from '../PriceHistory';
import { Schools } from '../Schools';

interface PropertyDetailsProps {
  property: PropertyDetailsFragment;
}

export const PropertyDetails = ({ property }: PropertyDetailsProps) => {
  return (
    <>
      <Box tag="section">
        <HomeDetails property={property} />
        <Divider mt={16} mb={16} />
      </Box>
      <Box tag="section">
        <Schools schools={property?.schools} schoolDistricts={property?.schoolDistricts} />
        <Divider mt={16} mb={16} />
      </Box>
      <Box tag="section">
        {property?.listingHistory && <PriceHistory listingHistory={property.listingHistory} />}
        <Divider mt={16} mb={40} />
      </Box>
    </>
  );
};
