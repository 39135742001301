import { TourTypeShowing } from '@opendoor/cinderblocks/marketplace/ExclusiveTourTypeSelector/ExclusiveTourTypeSelectorProps';

import { PropertyDetailsFragment } from '__generated__/athena';

const TWELVE_HOURS = 12;

export const convertDwellingType = (dwellingType?: string | null) => {
  switch (dwellingType) {
    case 'SINGLE_FAMILY':
      return 'Single family';
    case 'TOWNHOUSE':
      return 'Townhouse';
    case 'CONDO':
      return 'Condo';
    case 'MULTI_FAMILY':
      return 'Multi family';
    case 'OTHER':
      return 'Other';
    default:
      return 'Other';
  }
};

const formatHours = (date: Date): string => {
  let hour = date.getHours();
  const isAm = hour < TWELVE_HOURS;
  if (hour > TWELVE_HOURS) {
    hour -= TWELVE_HOURS;
  }
  const openSuffix = isAm ? 'AM' : 'PM';

  return `${hour}${openSuffix}`;
};

export const formatPrettyTimeRange = (start: Date, end: Date): string => {
  const weekday = start.toLocaleDateString([], { weekday: 'short' });
  const month = start.toLocaleDateString([], { month: 'short' });
  const date = start.getDate();
  const startTime = formatHours(start);
  const endTime = formatHours(end);

  return `${weekday} ${month} ${date}, ${startTime} - ${endTime}`;
};

export const nextAvailableOpenHouse = (
  property: PropertyDetailsFragment,
): TourTypeShowing | undefined => {
  if ((property.availableShowings?.length ?? 0) == 0) {
    return undefined;
  }

  const proposed = property.availableShowings?.find(
    (showing) => showing.access === 'PUBLIC' && showing.startAt && showing.endAt,
  );

  if (proposed) {
    return {
      id: proposed.id,
      startAt: new Date(proposed.startAt),
      endAt: new Date(proposed.endAt),
    };
  }

  return undefined;
};
