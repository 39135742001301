/* storybook-check-ignore */
import { useMemo } from 'react';

import { css } from '@emotion/react';
import { Box, BoxProps, PressableBox } from '@opendoor/bricks-next';
import { breakpoints } from '@opendoor/bricks/theme/eero';

import { is3PExclusive } from 'components/marketplace/helpers';
import useTrackProperty from 'components/marketplace/helpers/useTrackProperty';
import { Map, PropertyDetailsType } from 'components/marketplace/property';
import { generatePresetPictureSet, PresetPictureSet } from 'components/shared/performance/pictures';

const GALLERY_SOURCE_SET_CONFIG: PresetPictureSet = [
  {
    minWidth: breakpoints[2],
    maxWidth: breakpoints[3],
    preset: '3:2-md',
    presetScale: '2/3',
  },
  {
    minWidth: breakpoints[3],
    maxWidth: 1400,
    preset: '3:2-lg',
    presetScale: '3/4',
  },
  {
    minWidth: 1400,
    maxWidth: null,
    preset: '3:2-xl',
    presetScale: '1',
  },
];

interface GalleryPhotoProps {
  photoUrl: string;
  idx: number;
  toggleLightbox: () => void;
  setSelectedPhotoIndex: (idx: number) => void;
  isLastPhoto: boolean;
}

const GalleryPhoto = ({
  photoUrl,
  idx,
  toggleLightbox,
  setSelectedPhotoIndex,
  isLastPhoto,
}: GalleryPhotoProps) => {
  const { trackProperty } = useTrackProperty();
  const handleClick = () => {
    trackProperty('property-images', 'marketplace-pdp-lightbox');
    toggleLightbox();
    setSelectedPhotoIndex(idx);
  };

  return (
    <PressableBox
      analyticsName="cosmos-marketplace-property-desktop-gallery-photo"
      aria-label={`property photo ${idx + 1}`}
      key={`${idx}-photo`}
      mb={isLastPhoto ? 0 : 4}
      py={0}
      px={0}
      onPress={handleClick}
      width="100%"
    >
      {generatePresetPictureSet({
        debugImageSizes: false,
        photoUrl,
        sizes: GALLERY_SOURCE_SET_CONFIG,
        radii: {},
        lazy: idx !== 0,
      })}
    </PressableBox>
  );
};

interface GalleryPhotosProps {
  property: PropertyDetailsType;
  toggleLightbox: () => void;
  handleSetSelectedPhotoIndex: (idx: number) => void;
}
export const GalleryPhotos = ({
  property,
  toggleLightbox,
  handleSetSelectedPhotoIndex,
}: GalleryPhotosProps) => {
  const Gallery = useMemo(() => {
    const is3PE = is3PExclusive(property);
    const isExclusiveAvailableSoon = is3PE && property.listingState === 'AVAILABLE_SOON';

    const photos = property?.photos?.map((photo, idx) => {
      const galleryPhoto = (
        <GalleryPhoto
          key={idx}
          idx={idx}
          photoUrl={photo}
          toggleLightbox={toggleLightbox}
          setSelectedPhotoIndex={handleSetSelectedPhotoIndex}
          isLastPhoto={
            !isExclusiveAvailableSoon &&
            idx === (property.photos?.length && property.photos?.length - 1)
          }
        />
      );

      if (idx == 1) {
        return (
          <Box
            key="split-photo-map-view"
            flexDirection="row"
            mb={4}
            justifyContent="space-between"
            width="100%"
          >
            <Box width="49.75%" height="100%" aspectRatio={16 / 9} overflow="hidden">
              {galleryPhoto}
            </Box>
            <Box width="49.75%" aspectRatio={16 / 9}>
              <Map
                key="property-location"
                latitude={property.latitude}
                longitude={property.longitude}
              />
            </Box>
          </Box>
        );
      }

      return galleryPhoto;
    });

    if (isExclusiveAvailableSoon) {
      const props: BoxProps = {};
      if ((photos?.length ?? 0) > 0) {
        props.width = '100%';
        props.aspectRatio = 4 / 3;
      }

      photos?.splice(
        1,
        0,
        <Box {...props}>
          <Map
            key="property-location"
            latitude={property.latitude}
            longitude={property.longitude}
          />
        </Box>,
      );
    }

    return photos;
  }, []);
  return (
    <Box
      height="100vh"
      $smallerThanMD={{ display: 'none', overflow: 'hidden' }}
      $largerThanMD={{ flexBasis: '50%', overflow: 'scroll' }}
      $smallerThanLG={{ flexBasis: '50%', overflow: 'scroll' }}
      $largerThanLG={{ flex: 1 }}
      alignItems="center"
      mt={55}
      width="100%"
      css={css`
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; // IE and Edge
        scrollbar-width: none; // Firefox
      `}
    >
      {Gallery}
    </Box>
  );
};
