/* storybook-check-ignore */
import React, { useMemo } from 'react';

import { Accordion, Box, Button, Text } from '@opendoor/bricks-next';
import { Divider } from '@opendoor/bricks/core';
import { formatCurrency } from '@opendoor/bricks/helpers/currencyHelpers';

import { PropertyDetailsFragment } from '__generated__/athena';

import LongTextNew from 'components/properties/shared/LongTextNew';

import { convertDwellingType } from './helpers';

interface HomeDetailsProps {
  property: PropertyDetailsFragment;
}

const convertKitchenCountertop = (kitchenCountertop: string) => {
  switch (kitchenCountertop) {
    case 'GRANITE_SLAB':
      return 'Granite slab';
    case 'GRANITE_TILE':
      return 'Granite tile';
    case 'QUARTZ':
      return 'Quartz';
    case 'CORIAN':
      return 'Corian';
    case 'FORMICA':
      return 'Formica';
    default:
      return 'Other';
  }
};

const convertMasterBedroomLocation = (
  masterBedroomLocation?: string | null,
  exteriorStories?: number | null,
) => {
  switch (masterBedroomLocation) {
    case 'FIRST_FLOOR':
      if (exteriorStories && exteriorStories > 1) {
        return 'Downstairs bedroom';
      }
      return 'Main bedroom';
    case 'SECOND_FLOOR':
    case 'THIRD_FLOOR':
      return 'Upstairs bedroom';
    default:
      return null;
  }
};

export const HomeDetails: React.FC<HomeDetailsProps> = ({ property }: HomeDetailsProps) => {
  const masterBedroomLocation = convertMasterBedroomLocation(
    property.masterBedroomLocation,
    property.exteriorStories,
  );

  const rows: Array<{ title: string; value: string | number | null }> = [
    {
      title: 'Home type',
      value: property?.dwellingType ? convertDwellingType(property.dwellingType) : null,
    },
    {
      title: 'Lot size',
      value: property?.sqFtLot ? `${property.sqFtLot.toLocaleString()} sqft lot` : null,
    },
    {
      title: 'Price per sqft',
      value: property?.pricePerSqFt ? `${formatCurrency(property.pricePerSqFt)} / sqft` : null,
    },
    {
      title: 'Built in',
      value: property?.yearBuilt ?? null,
    },
    {
      title: 'Kitchen',
      value: property?.kitchenCountertop
        ? convertKitchenCountertop(property.kitchenCountertop)
        : null,
    },
    {
      title: 'Garage',
      value: property?.garageSpaces ? `${property.garageSpaces} car garage` : null,
    },
    {
      title: 'Master bedroom',
      value: masterBedroomLocation,
    },
  ];

  const description = useMemo(() => {
    const filteredRows = rows.filter((row) => row.value !== null);
    const showPropertyDescription = property.listingState === 'ON_THE_MARKET';
    return (
      <Box width="100%" id="homeDetails">
        {showPropertyDescription && property?.description && (
          <Box $smallerThanMD={{ mb: 20 }} pb={24}>
            <LongTextNew content={property?.description} limit={212} />
          </Box>
        )}
        {filteredRows.map((row, index) =>
          row.value ? (
            <Box key={`homeDetail_${index}`}>
              <Box flexDirection="row" justifyContent="space-between" width="100%">
                <Text tag="p" typography="$bodySmall" color="$contentPrimary">
                  {row.title}
                </Text>
                <Text tag="p" typography="$labelSmall" color="$contentPrimary">
                  {row.value}
                </Text>
              </Box>
              {index !== filteredRows.length - 1 ? <Divider my={5} /> : null}
            </Box>
          ) : null,
        )}
        {property?.sellerDisclosuresDownloadUrl && (
          <Button
            label="Download seller&rsquo;s disclosure"
            analyticsName="seller_disclosure_link"
            href={property.sellerDisclosuresDownloadUrl}
            size="small"
            target="_blank"
            variant="secondary"
            mt={24}
            mb={24}
          ></Button>
        )}
      </Box>
    );
  }, [rows]);

  return (
    <>
      <Box
        $smallerThanLG={{ display: 'none' }}
        data-testid="marketplace-home-details-accordion-desktop"
      >
        <Accordion
          items={[
            {
              title: 'Home details',
              description,
            },
          ]}
          variant="medium"
          size="xlarge"
          isFullWidth
        />
      </Box>
      <Box
        $largerThanMD={{ display: 'none' }}
        data-testid="marketplace-home-details-accordion-mobile"
      >
        <Accordion
          items={[
            {
              title: 'Home details',
              description,
            },
          ]}
          variant="medium"
          size="large"
          isFullWidth
        />
      </Box>
    </>
  );
};
