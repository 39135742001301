/* storybook-check-ignore */
import { Box, Text } from '@opendoor/bricks-next';
import { formatCurrency } from '@opendoor/shared-fe/formatUtils';

interface PricingTraditionalProps {
  listPrice?: number | null;
}

export const PricingTraditional = ({ listPrice }: PricingTraditionalProps) => {
  return (
    <Box flexDirection="row" alignItems="center">
      <Text typography="$headerMedium" tag="h2" color="$contentPrimary" pb={24}>
        {listPrice ? formatCurrency(listPrice) : 'Price not available'}
      </Text>
    </Box>
  );
};

interface PricingBuyItNowProps {
  buyDirectPrice?: number | null;
  listPrice?: number | null;
}

export const PricingBuyItNow = ({ buyDirectPrice, listPrice }: PricingBuyItNowProps) => {
  return (
    <Box flexDirection="row" alignItems="flex-end" pb={24}>
      <Box flexDirection="column">
        <Text
          typography="$headerMedium"
          tag="h2"
          color="$contentPrimary"
          $smallerThanMD={{ typography: '$subheaderSmall' }}
        >
          {buyDirectPrice ? formatCurrency(buyDirectPrice) : 'Price not available'}
        </Text>
        <Text
          typography="$bodySmall"
          tag="span"
          color="$contentTertiary"
          $smallerThanMD={{ typography: '$bodyXsmall' }}
        >
          Buy directly from Opendoor
        </Text>
      </Box>
      <Box flexDirection="column" ml={24}>
        <Text
          typography="$labelXlarge"
          tag="h2"
          color="$contentTertiary"
          textDecorationLine="line-through"
          $smallerThanMD={{ typography: '$labelLarge' }}
        >
          {listPrice ? formatCurrency(listPrice) : 'Price not available'}
        </Text>
        <Text
          typography="$bodySmall"
          tag="span"
          color="$contentTertiary"
          $smallerThanMD={{ typography: '$bodyXsmall' }}
        >
          Public list price
        </Text>
      </Box>
    </Box>
  );
};
