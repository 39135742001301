import { createContext, useContext } from 'react';

import { TourRequestFragment } from '__generated__/athena';

export interface CustomerTours {
  loading: boolean;
  cancelling: boolean;
  tours: TourRequestFragment[];
  refetch: () => Promise<void>;
  cancelTour: (tourId: string) => Promise<void>;
}

export const CustomerToursContext = createContext<CustomerTours | null>(null);

export const useCustomerToursContext = (): CustomerTours => {
  const context = useContext(CustomerToursContext);

  if (!context) {
    throw new Error('CustomerToursContext must be used within CustomerToursProvider');
  }

  return context;
};
