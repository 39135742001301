/* storybook-check-ignore */
import { useCallback } from 'react';

import { Box, Button, Text } from '@opendoor/bricks-next';
import { Divider } from '@opendoor/bricks/core';

import { useTrackProperty } from 'components/marketplace/helpers';
import { useViewCount, VIEW_COUNT_THRESHOLD } from 'components/marketplace/helpers/useViewCount';

import { useObservability } from '../../../helpers/observability';
import { CONSUMER_URL } from '../../globals';

const VIDEO_URL = 'https://s3.amazonaws.com/odfil.es/static/videos/bd_key.mp4';

export const BuyDirectCTA = ({ addressToken }: { addressToken: string }) => {
  const { viewCount } = useViewCount();
  const { trackProperty } = useTrackProperty();
  const { trackJourneyEvent } = useObservability();

  const trackOnBuyDirectClick = useCallback(() => {
    trackProperty('property-buy-direct', 'marketplace-pdp');
    trackJourneyEvent('marketplaceProperty', 'offerStart', {
      addressUuid: addressToken,
      source: 'marketplace-pdp',
    });
  }, []);

  const trackOnSelfTourClick = useCallback(() => {
    trackProperty('property-self-tour', 'marketplace-pdp');
    trackJourneyEvent('marketplaceProperty', 'tourBookingStart', {
      addressUuid: addressToken,
      placement: 'above-the-fold',
    });
  }, []);

  const shouldShowAnimation = viewCount && viewCount < VIEW_COUNT_THRESHOLD;

  return shouldShowAnimation ? (
    <Box flexDirection="column" alignItems="center" alignContent="center">
      <Box width="100%" $smallerThanMD={{ mt: 24 }} mt={48} mb={24}>
        <Divider />
      </Box>
      <Box alignItems="center">
        <Box>
          <video width="100%" height="220px" autoPlay loop playsInline muted>
            <source src={VIDEO_URL} type="video/mp4" />
          </video>
        </Box>
        <Text
          tag="h5"
          typography="$subheaderXsmall"
          $smallerThanMD={{ fontSize: 20 }}
          color="$contentPrimary"
        >
          Never lose the home you love.
        </Text>
        <Text
          tag="h5"
          typography="$subheaderXsmall"
          $smallerThanMD={{ fontSize: 20 }}
          color="$contentTertiary"
        >
          Start now - back out anytime.
        </Text>
      </Box>
      <Button
        analyticsName="cosmos-marketplace-buy-direct-animated-cta"
        variant="secondary"
        size="small"
        mt={24}
        onPress={trackOnBuyDirectClick}
        target="_blank"
        rel="noopener"
        label="Buy now"
        width="100%"
        $largerThanMD={{ maxWidth: 300 }}
        href={`${CONSUMER_URL}/dashboard/buy/checkout?id=${addressToken}`}
      />
    </Box>
  ) : (
    <Box flexDirection="row" justifyContent="space-between">
      <Button
        analyticsName="cosmos-marketplace-buy-direct-cta"
        variant="secondary"
        size="small"
        mt={24}
        onPress={trackOnBuyDirectClick}
        target="_blank"
        rel="noopener"
        label="Buy now"
        width="48%"
        href={`${CONSUMER_URL}/dashboard/buy/checkout?id=${addressToken}`}
      />
      <Button
        analyticsName="cosmos-marketplace-self-tour-cta"
        variant="secondary"
        size="small"
        mt={24}
        onPress={trackOnSelfTourClick}
        target="_blank"
        rel="noopener"
        label="Self-tour"
        width="48%"
        href={`${CONSUMER_URL}/dashboard/buy/tour?id=${addressToken}`}
      />
    </Box>
  );
};
