/* storybook-check-ignore */
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import styled from '@emotion/styled';
import { Box } from '@opendoor/bricks/core';
import Button from '@opendoor/bricks/core/Button';
import Divider from '@opendoor/bricks/core/Divider/Divider';
import Icon from '@opendoor/bricks/core/Icon/Icon';
import { useDevice } from '@opendoor/bricks/hooks/useMedia';
import { Themed } from '@opendoor/bricks/theme/ODTheme';

import { NavigationBar, NavigationBarProps } from './NavigationBar';

export const setAppElement = (appElement: string | HTMLElement) => {
  ReactModal.setAppElement(appElement);
};

const CloseButtonStyled = styled(Button)`
  border: none;
  box-shadow: none;
`;

interface BaseModalProps {
  /**
   * Name of the modal in analytics events, should be unique within the app
   * Convention: {appName}-{modalName}
   */
  analyticsName: string;
  isOpen: boolean;
  height?: string;
  minHeight?: number | string;
  overflow?: string;
  shouldCloseOnOverlayClick?: boolean;
  onRequestClose?: () => void;
}

interface NavigationBarWithTitleProps extends NavigationBarProps {
  title?: string;
}

interface ModalWithCloseButtonAndTitleProps extends BaseModalProps {
  headerProps: NavigationBarWithTitleProps;
}

interface ModalWithAriaLabelledByProps extends BaseModalProps {
  /* TODO - @platform Make ariaLabelledBy required for this type */
  ariaLabelledBy?: string;
  headerProps?: NavigationBarProps;
}

interface ModalWithContentLabelProps extends BaseModalProps {
  /* TODO - @platform Make contentLabel required for this type */
  contentLabel?: string;
  headerProps?: NavigationBarProps;
}

/* To meet accessibility standards, the Modal component must receive either headerProps.title, ariaLabelledBy, or contentLabel. */
export type ModalProps =
  | ModalWithCloseButtonAndTitleProps
  | ModalWithAriaLabelledByProps
  | ModalWithContentLabelProps;

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  analyticsName,
  isOpen = false,
  height,
  minHeight = 200,
  overflow = 'auto',
  shouldCloseOnOverlayClick = true,
  onRequestClose,
  ...props
}) => {
  const [isScrollingBodyPrevented, setisScrollingBodyPrevented] = useState(false);
  const [showDivider, setShowDivider] = useState(false);
  const handleScroll = useCallback<React.UIEventHandler<HTMLDivElement>>(
    (e) => setShowDivider(e.currentTarget.scrollTop > 0),
    [],
  );
  const { isMobile } = useDevice();

  /*
      Scrolling needs to be blocked on elements behind the modal for a better accessable design.
      The useEffect below blocks scrolling when modal is opened.
      https://chakra-ui.com/docs/overlay/modal#keyboard-and-focus-management
  */
  useEffect(() => {
    // Need specific styling for iOS body touchscreen
    // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    if (!isOpen && !isScrollingBodyPrevented) {
      // does not run rest of code in the useEffect since modal has not been opened & is not in the process of being opened
      // this is needed for pages that have multiple modals on the page
      return;
    }
    if (isMobile && isOpen) {
      document.body.style.overflow = 'hidden';
      setisScrollingBodyPrevented(true);
    } else if (isMobile && !isOpen) {
      document.body.style.overflow = 'visible';
      setisScrollingBodyPrevented(true);
    } else if (isOpen) {
      // non-mobile styling
      document.body.style.overflow = 'hidden';
      setisScrollingBodyPrevented(true);
    } else {
      document.body.style.overflow = 'visible';
      setisScrollingBodyPrevented(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isScrollingBodyPrevented]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        content: {
          margin: 'auto',
          overflow,
          minHeight,
          padding: 0,
          left: 'auto',
          right: 'auto',
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'start',
          position: 'fixed',
          maxHeight: '100%',
          maxWidth: '100vw',
          width: '100vw',
          height: '100%',
        },
        overlay: {
          zIndex: 5,
        },
      }}
      /*
        Accessibility note: Aria-model indicates the presence of a modal element
        https://www.w3.org/TR/wai-aria-1.2/#aria-modal
      */
      aria-modal={isOpen}
    >
      <Themed theme="novo">
        {/*
          NOTE: Wrapping children in <Themed /> since usually the modal is a
          sibling to any root App component that is wrapped in the theme
      */}
        {'headerProps' in props && (
          <>
            <Box flexGrow={0} zIndex={2000}>
              <NavigationBar
                rightComponent={
                  <CloseButtonStyled
                    id="modal-close-button"
                    onClick={onRequestClose}
                    variant="icon"
                    analyticsName={`${analyticsName}-close`}
                    aria-label="Close"
                  >
                    <Icon name="close" size={16} aria-hidden="true" />
                  </CloseButtonStyled>
                }
                {...('headerProps' in props ? props.headerProps : {})}
              />
            </Box>
            {showDivider && <Divider />}
          </>
        )}
        <Box
          p={0}
          flexGrow={1}
          overflowY="scroll"
          sx={{
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
          overflow={overflow}
          onScroll={handleScroll}
        >
          {props.children}
        </Box>
      </Themed>
    </ReactModal>
  );
};

export default Modal;
