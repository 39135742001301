/* storybook-check-ignore */
import { FC, ReactElement, useState } from 'react';

import { Box, Dialog, Link, Text } from '@opendoor/bricks-next';

interface ExclusiveBenefitsHeader {
  children: ReactElement;
  isExclusive: boolean;
}

interface Benefit {
  name: string;
  explanation: string;
}

const benefits: Benefit[] = [
  {
    name: 'Be the first to tour',
    explanation: 'Unlock ‘the one’ home weeks before other buyers.',
  },
  {
    name: 'Early access prices',
    explanation: 'Find your next home at a price you’ll also love. ',
  },
  {
    name: 'Agents are covered',
    explanation: 'We’ll pay up to 3% of the fee you’ve agreed on with your agent.',
  },
  {
    name: 'Get $50,000 appraisal match',
    explanation: 'If the home appraises at a lower price, we’ll match it up to $50,000.',
  },
];

const ExclusiveBenefitsHeader: FC<ExclusiveBenefitsHeader> = ({ children, isExclusive }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (isExclusive) {
    return (
      <>
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
          <Dialog.Content
            title="Exclusively on Opendoor"
            description="Tour and buy homes before they're listed anywhere else"
          >
            <Box my="$12x" gap="$12x">
              {benefits.map((benefit) => (
                <Box key={benefit.name} gap="$4x">
                  <Text
                    tag="span"
                    typography="$labelLarge"
                    color="$contentPrimary"
                    fontWeight="$medium"
                    $smallerThanMD={{ typography: '$labelMedium' }}
                  >
                    {benefit.name}
                  </Text>
                  <Text tag="span" typography="$bodySmall" color="$contentTertiary">
                    {benefit.explanation}
                  </Text>
                </Box>
              ))}
            </Box>
          </Dialog.Content>
          <Dialog.Actions
            buttonOneProps={{
              variant: 'primary',
              analyticsName: 'cosmos-exclusive-close-benefits',
              label: 'Close',
              onPress: () => setIsOpen(false),
            }}
          />
        </Dialog>
        <Box backgroundColor="$backgroundInverseAccentBrand" borderRadius="$12x" width="100%">
          <Box
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="$4x"
            pt="$3x"
            pb="$1x"
          >
            <Text tag="span" typography="$labelSmall" color="$backgroundPrimary">
              Exclusively on Opendoor
            </Text>
            <Link
              analyticsName="cosmos-exclusive-view-benefits"
              variant="secondaryInverse"
              size="small"
              onPress={() => setIsOpen(true)}
            >
              View benefits
            </Link>
          </Box>
          {children}
        </Box>
      </>
    );
  }

  return children;
};

export default ExclusiveBenefitsHeader;
