/* storybook-check-ignore */
import { ComponentType, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';

import { useOnLoad } from '../useOnLoad';

export function DynamicComponentAfterLoad<T extends object>(
  props: T & {
    dynamicLoaderFunction: () => Promise<ComponentType<T>>;
    children?: React.ReactNode;
  },
): JSX.Element {
  const afterLoad = useOnLoad();
  // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/14729
  // works in a later version of typescript (4.8.4)
  return <DynamicComponentWithCondition<T> loadingCondition={afterLoad} {...props} />;
}

/**
 * Load a component by:
 *   1. Waiting for a condition to be true
 *   2. Kicking off it's dynamic promise at that time
 *
 * The default dynamic import from next/dynamic only sort-of saves bundle size
 * but it still contributes a lot to active parsing time.
 */
export function DynamicComponentWithCondition<T extends object>(
  props: T & {
    dynamicLoaderFunction: () => Promise<ComponentType<T>>;
    loadingCondition: boolean;
    children?: JSX.Element;
  },
): JSX.Element {
  const [LoadableComponent, setLoadableComponent] = useState<ComponentType<T> | null>(null);
  useEffect(() => {
    if (!props.loadingCondition) {
      return;
    }
    setLoadableComponent(dynamic<T>(props.dynamicLoaderFunction, { ssr: false }));
  }, [props.loadingCondition]);
  const componentProps: T = {
    ...props,
    dynamicLoaderFunction: undefined,
    loadingCondition: undefined,
  };

  return props.loadingCondition && LoadableComponent ? (
    <LoadableComponent {...componentProps} />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
}
