/* storybook-check-ignore */
import React, { useMemo, useRef, useState } from 'react';

import {
  Box,
  ChevronBackButton,
  Image,
  PressableBox,
  ShareButton,
  Text,
} from '@opendoor/bricks-next';
import { formatCurrency } from '@opendoor/bricks/helpers/currencyHelpers';

import { Map } from 'components/marketplace/property';
import { DynamicComponentWithCondition } from 'components/shared/dynamic';
import { useInViewAfterLoad } from 'components/shared/hooks/useInView';

import type { InteractiveGoogleStreetViewProps } from '../../../properties/components/InteractiveGoogleStreetView';
import Modal from './Modal';
import { ModalContent } from './PhotoCarousel';

interface MobileModalContentProps {
  address?: string | null;
  closeModal: () => void;
  contentToShow: ModalContent;
  isModalOpen: boolean;
  latitude?: number | null;
  longitude?: number | null;
  openLightbox: (idx: number) => void;
  photos: string[] | null | undefined;
  price?: number | null;
  openShareModal: () => void;
}

enum MapContent {
  MAP = 'Map',
  STREETVIEW = 'Street view',
}

export const MobileModalContent: React.FC<MobileModalContentProps> = React.memo(
  ({
    address,
    closeModal,
    contentToShow,
    isModalOpen,
    latitude,
    longitude,
    openLightbox,
    photos,
    price,
    openShareModal,
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [content, setContent] = useState<MapContent>(MapContent.MAP);
    const { inView: inViewMapPage } = useInViewAfterLoad({
      triggerOnce: true,
      initialInView: true,
      ref,
    });

    const mappedPhotos = useMemo(
      () =>
        photos?.map(
          (photo) => {
            return {
              url: photo + '?service=cosmos&preset=3%3A2-md&presetScale=2%2F3&dpr=1',
              alt: '',
            };
          },
          [photos],
        ) || [],
      [photos],
    );

    const switchToMap = () => setContent(MapContent.MAP);
    const switchToStreetView = () => setContent(MapContent.STREETVIEW);

    return (
      <Modal
        overflow={contentToShow === ModalContent.MAP ? 'hidden' : 'auto'}
        isOpen={isModalOpen}
        analyticsName="marketplace-mobile-photo-gallery-modal"
        onRequestClose={closeModal}
        headerProps={{
          rightComponent: (
            <ShareButton
              analyticsName="marketplace-mobile-photo-gallery-share-button"
              size="medium"
              onPress={openShareModal}
            />
          ),
          title: price ? formatCurrency(price) : 'Price not available',
          titleProps: {
            fontSize: '16px',
            alignSelf: 'center',
            paddingTop: '12px',
          },
          subtitleProps: {
            fontSize: '12px',
            alignSelf: 'center',
            color: 'warmgrey800',
          },
          subtitle: address || 'Address not available',
          leftComponent: (
            <ChevronBackButton
              analyticsName="marketplace-mobile-photo-gallery-back-button"
              size="medium"
              onPress={closeModal}
              data-testid="marketplace-mobile-photo-gallery-back-button"
            />
          ),
        }}
      >
        <Box height="100%" data-testid="mobile-image-gallery">
          {contentToShow === ModalContent.PHOTOS &&
            mappedPhotos.map((photo, idx) => (
              <PressableBox
                key={`${idx}-photo`}
                mb="$3x"
                onPress={() => openLightbox(idx)}
                analyticsName="marketplace-mobile-photo-gallery-photo-clicked"
                aria-label={`image gallery photo ${idx + 1}`}
                padding={0}
              >
                <Image
                  alt={photo.alt || 'property photo'}
                  source={{ uri: photo.url }}
                  width="100%"
                  height="auto"
                  aspectRatio={3 / 2}
                />
              </PressableBox>
            ))}
          {contentToShow === ModalContent.MAP && latitude && longitude && (
            <Box height="100%" flexDirection="column">
              <Box
                backgroundColor="$backgroundPrimary"
                flexDirection="row"
                justifyContent="center"
                height={50}
                alignItems="center"
                width="100%"
                minHeight={50}
              >
                <PressableBox
                  analyticsName="marketplace-mobile-modal-map-button"
                  aria-label="Map"
                  flexDirection="row"
                  justifyContent="center"
                  px={20}
                  width="50%"
                  onPress={switchToMap}
                  height="100%"
                >
                  <Text
                    tag="span"
                    typography="$labelMedium"
                    color={content === MapContent.MAP ? '$contentPrimary' : '$contentTertiary'}
                    fontWeight={content === MapContent.MAP ? '$medium' : '$regular'}
                  >
                    Map
                  </Text>
                </PressableBox>
                <PressableBox
                  analyticsName="marketplace-mobile-modal-street-view-button"
                  aria-label="Street view"
                  flexDirection="row"
                  justifyContent="center"
                  px={20}
                  width="50%"
                  onPress={switchToStreetView}
                  height="100%"
                >
                  <Text
                    tag="span"
                    typography="$labelMedium"
                    color={
                      content === MapContent.STREETVIEW ? '$contentPrimary' : '$contentTertiary'
                    }
                    fontWeight={content === MapContent.STREETVIEW ? '$medium' : '$regular'}
                  >
                    Street view
                  </Text>
                </PressableBox>
              </Box>
              <Box height="calc(100% - 50px)">
                {content === MapContent.MAP && (
                  <Map key="property-location" latitude={latitude} longitude={longitude} />
                )}
                {content === MapContent.STREETVIEW && (
                  <Box height="100%">
                    <DynamicComponentWithCondition<InteractiveGoogleStreetViewProps>
                      dynamicLoaderFunction={() =>
                        import('../../../properties/components/InteractiveGoogleStreetView').then(
                          (mod) => mod.InteractiveGoogleStreetView,
                        )
                      }
                      loadingCondition={inViewMapPage}
                      latitude={latitude}
                      longitude={longitude}
                      addressControl
                      linksControl
                      zoomControl
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    );
  },
);

MobileModalContent.displayName = 'MobileModalContent';
