/* storybook-check-ignore */
import React, { useState } from 'react';

import { Text, TextButton } from '@opendoor/bricks-next';

interface LongTextNewProps {
  content: string;
  limit: number;
}

const LongTextNew: React.FC<LongTextNewProps> = ({ content, limit }) => {
  const [showAll, setShowAll] = useState(false);

  const handleOnPress = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const label = showAll ? 'Read less' : 'Read more';

  if (content?.length <= limit) {
    return (
      <Text tag="p" typography="$bodyMedium" color="$contentSecondary">
        {content}
      </Text>
    );
  }

  return (
    <Text tag="p" typography="$bodyMedium" color="$contentSecondary">
      {showAll ? content : content.substring(0, limit) + '...'}
      <TextButton
        analyticsName="cosmos-long-text-read"
        aria-label={label}
        onPress={handleOnPress}
        variant="secondary"
        backgroundColor="$transparent"
        size="xsmall"
        label={label}
        mt={8}
      />
    </Text>
  );
};

export default LongTextNew;
