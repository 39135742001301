/* storybook-check-ignore */
import React, { useRef } from 'react';

import { Box } from '@opendoor/bricks-next';

import type { MapContainerProps } from 'components/marketplace/property/MapContainer';
import { DynamicComponentWithCondition } from 'components/shared/dynamic';
import { useInViewAfterLoad } from 'components/shared/hooks/useInView';

export const Map: React.FC<MapContainerProps> = ({ latitude, longitude }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inView: inViewMapPage } = useInViewAfterLoad({
    triggerOnce: true,
    initialInView: true,
    ref,
  });
  return (
    <Box height="100%" width="100%">
      <DynamicComponentWithCondition<MapContainerProps>
        dynamicLoaderFunction={() =>
          import('components/marketplace/property/MapContainer').then((mod) => mod.default)
        }
        loadingCondition={inViewMapPage}
        latitude={latitude}
        longitude={longitude}
      />
    </Box>
  );
};
