/* storybook-check-ignore */
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  PhotoCarousel as BricksPhotoCarousel,
  ChevronBackButton,
  Image,
  PhotoCarouselAspectRatios,
  PressableBox,
  ShareButton,
} from '@opendoor/bricks-next';

import useTrackProperty from 'components/marketplace/helpers/useTrackProperty';

import { MobileModalContent } from './MobileModalContent';

export enum ModalContent {
  PHOTOS = 'photos',
  MAP = 'map',
}

interface PhotoCarouselProps {
  photos: string[] | null | undefined;
  address?: string | null;
  price?: number | null;
  toggleLightbox?: () => void;
  setSelectedPhotoIndex?: (idx: number) => void;
  latitude?: number | null;
  longitude?: number | null;
  openShareModal: () => void;
}

const MAP_MARKER_ICON =
  'https://images.opendoor.com/source/s3/imgdrop-production/2024-06-1719508347054-73258.png?preset=square-128';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
export const PhotoCarousel = ({
  photos,
  address,
  price,
  toggleLightbox,
  setSelectedPhotoIndex,
  latitude,
  longitude,
  openShareModal,
}: PhotoCarouselProps) => {
  const { trackProperty } = useTrackProperty();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aspectRatio, setAspectRatio] = useState<number>(PhotoCarouselAspectRatios.DEFAULT);
  const [modalContent, setModalContent] = useState<ModalContent>(ModalContent.PHOTOS);
  const carouselRef = useRef<HTMLDivElement>(null);

  const mappedPhotos = useMemo(
    () =>
      photos?.map(
        (photo) => {
          return {
            url: photo + '?service=cosmos&preset=3%3A2-md&presetScale=2%2F3&dpr=1',
            alt: '',
          };
        },
        [photos],
      ) || [],
    [photos],
  );

  const togglePhotoModal = () => {
    trackProperty('property-images', 'marketplace-pdp-mobile-gallery');
    setIsModalOpen((prev) => !prev);
    setModalContent(ModalContent.PHOTOS);
  };

  const toggleMapModal = () => {
    setIsModalOpen((prev) => !prev);
    setModalContent(ModalContent.MAP);
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    let startY = 0;
    let startX = 0;

    const handleTouchStart = (e: TouchEvent) => {
      startY = e.touches[0].clientY; // Set start Y to the initial touch Y position
      startX = e.touches[0].clientX; // Set start X to the initial touch X position
    };

    const handleTouchMove = (e: TouchEvent) => {
      // Calculate the difference between current touch position and start position
      const moveY = e.touches[0].clientY - startY;
      const moveX = e.touches[0].clientX - startX;

      // We're doing this because we want to allow vertical scrolling
      // if the user starts scrolling vertically first
      // But if they start scrolling horizontally first, we want to prevent vertical scrolling
      if (Math.abs(moveX) > Math.abs(moveY)) {
        e.preventDefault(); // Prevent Vertical scrolling
      } else {
        e.stopPropagation(); // Prevent Horizontal scrolling
      }
    };

    if (carousel) {
      carousel.addEventListener('touchstart', handleTouchStart, { passive: true });
      carousel.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    return () => {
      if (carousel) {
        // Clean up event listeners
        carousel.removeEventListener('touchstart', handleTouchStart);
        carousel.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [carouselRef]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const CarouselWidths = {
    smallest: 360,
    small: 375,
    smallMedium: 600,
    medium: 768,
    mediumLarge: 992,
  };

  const WIDTH_TO_ASPECT_RATIO = {
    [CarouselWidths.smallest]: PhotoCarouselAspectRatios.DEFAULT,
    [CarouselWidths.small]: PhotoCarouselAspectRatios.SQUARE,
    [CarouselWidths.smallMedium]: PhotoCarouselAspectRatios.SQUARE,
    [CarouselWidths.medium]: PhotoCarouselAspectRatios.SQUARE,
    [CarouselWidths.mediumLarge]: PhotoCarouselAspectRatios.SQUARE,
  };

  useIsomorphicLayoutEffect(() => {
    // Function to calculate and set the aspect ratio
    const updateAspectRatio = () => {
      const containerWidth = window.innerWidth;
      let newAspectRatio = PhotoCarouselAspectRatios.DEFAULT; // Default aspect ratio

      if (containerWidth < CarouselWidths.smallest) {
        newAspectRatio = PhotoCarouselAspectRatios.DEFAULT;
      } else if (containerWidth <= CarouselWidths.small) {
        newAspectRatio = WIDTH_TO_ASPECT_RATIO[CarouselWidths.small];
      } else if (containerWidth < CarouselWidths.smallMedium) {
        newAspectRatio = WIDTH_TO_ASPECT_RATIO[CarouselWidths.smallMedium];
      } else if (containerWidth <= CarouselWidths.medium) {
        newAspectRatio = WIDTH_TO_ASPECT_RATIO[CarouselWidths.medium];
      } else if (containerWidth <= CarouselWidths.mediumLarge) {
        newAspectRatio = WIDTH_TO_ASPECT_RATIO[CarouselWidths.mediumLarge];
      }

      setAspectRatio(newAspectRatio);
    };

    // Call updateAspectRatio initially to set the aspect ratio based on the current window size
    updateAspectRatio();

    // Add event listener for window resize
    window.addEventListener('resize', updateAspectRatio);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', updateAspectRatio);
  }, []);

  const openLightbox = (selectedImageIndex: number) => {
    trackProperty('property-images', 'marketplace-pdp-lightbox');
    setSelectedPhotoIndex && setSelectedPhotoIndex(selectedImageIndex);
    toggleLightbox && toggleLightbox();
  };

  return (
    <Box
      aspectRatio={aspectRatio}
      width="100%"
      ref={carouselRef}
      $largerThanSM={{ display: 'none' }}
      mt={55}
    >
      <BricksPhotoCarousel photos={mappedPhotos} hideNavigation aspectRatio={aspectRatio}>
        <ChevronBackButton
          size="large"
          onPress={() => history.back()}
          analyticsName="marketplace-carousel-back-button"
          top="$12x"
          left={24}
          position="absolute"
          zIndex={1}
        />
        <ShareButton
          size="large"
          onPress={openShareModal}
          analyticsName="marketplace-carousel-share-button"
          top="$12x"
          right={24}
          position="absolute"
          zIndex={1}
        />
        {latitude && longitude && (
          <PressableBox
            aria-label="Click to view map"
            onPress={toggleMapModal}
            analyticsName="marketplace-carousel-map-button"
            bottom="$10x"
            right={24}
            position="absolute"
            zIndex={1}
            pressStyle={{
              scale: 0.95,
            }}
            width={64}
            height={64}
          >
            <Image
              alt="map-button"
              source={{
                height: 64,
                uri: MAP_MARKER_ICON,
                width: 64,
              }}
            />
          </PressableBox>
        )}
        <PressableBox
          // This PressableBox as an absolutely positioned transparent button is required in
          // order to support the gallery modal. Using a PressableBox wrapper creates a nextjs Hydration
          // error with the nested buttons so we need to do it this way.
          aria-label="Click to view more photos"
          onPress={togglePhotoModal}
          analyticsName="marketplace-carousel-photo-clicked"
          padding={0}
          width="100%"
          height="100%"
          position="absolute"
          backgroundColor="$transparent"
        />
      </BricksPhotoCarousel>
      <MobileModalContent
        address={address}
        closeModal={closeModal}
        contentToShow={modalContent}
        isModalOpen={isModalOpen}
        photos={photos}
        price={price}
        latitude={latitude}
        longitude={longitude}
        openLightbox={openLightbox}
        openShareModal={openShareModal}
      />
    </Box>
  );
};
