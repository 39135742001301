/* storybook-check-ignore */
import React, { useEffect, useRef } from 'react';

import { Box } from '@opendoor/bricks/core';
import mapboxgl, { Map, PointLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { MAPBOX_KEY } from '../../exclusives/constants/constants';

export interface MapContainerProps {
  latitude: number;
  longitude: number;
}

export const MapContainer: React.FC<MapContainerProps> = ({ latitude, longitude }) => {
  const mapContainer = useRef(null);
  const map = useRef<Map | null>(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer?.current || '',
      accessToken: MAPBOX_KEY,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [longitude, latitude],
      zoom: 12,
    });
    map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-left');

    // disable map zoom when using scroll
    map.current.scrollZoom.disable();

    // load the marker
    const img = document.createElement('img');
    img.src =
      'https://images.opendoor.com/source/s3/imgdrop-production/2024-07-1720722843921-87270.png?preset=square-2048';
    img.style.width = '20px';

    new mapboxgl.Marker(img, { offset: { x: 0, y: 0 } as PointLike })
      .setLngLat([longitude, latitude])
      .addTo(map.current);
  });

  return <Box height="100%" width="100%" ref={mapContainer} />;
};

export default MapContainer;
