/* storybook-check-ignore */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Button, Text } from '@opendoor/bricks-next';
import { PropertyCard } from '@opendoor/cinderblocks/marketplace/PropertyCard';

import { usePropertyReactions } from 'components/marketplace/helpers';

import { COSMOS_URL } from '../../globals';
import { convertDwellingType } from './helpers';

// This hook is used to lazy load the photo URL when the component is in view
// It uses the Intersection Observer API to detect when the component is in the viewport
// and sets the photo URL to be loaded only then
// This helps in optimizing performance by not loading images that are not visible to the user
// and reduces the initial load time of the component
const useLazyLoadPhoto = (photoUrl: string | null) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current || isLoaded) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoaded(true);
          observer.disconnect();
        }
      },
      { rootMargin: '200px' },
    );
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [isLoaded]);

  return { containerRef, loadedPhotoUrl: isLoaded ? photoUrl : null };
};

interface Tracking {
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  utmTerm?: string | null;
}

interface ListingCard {
  addressToken?: string | null;
  baths?: number | null;
  beds?: number | null;
  city?: string | null;
  dwellingType?: string | null;
  fullAddress?: string | null;
  estimatedMonthlyPayment?: number | null;
  listPrice?: number | null;
  livingSquareFootage?: number | null;
  neighborhood?: string | null;
  slug?: string | null;
  photos?: string[] | null;
  isLiked?: boolean | null;
}

interface SimilarListingsProps {
  listings: ListingCard[];
  title?: string | null;
}

interface ListingCardComponentProps {
  listing: ListingCard;
}

export const ListingCard: React.FC<ListingCardComponentProps & Tracking> = ({
  utmSource,
  utmMedium,
  utmCampaign,
  utmContent,
  utmTerm,
  listing,
}) => {
  const { isLiked, onClickReaction } = usePropertyReactions(
    listing?.addressToken,
    'REACTION_SOURCE_UNKNOWN',
  );

  const photoUrl =
    `${listing?.photos?.[0]}?${new URLSearchParams({
      service: 'cosmos',
      preset: '3:2-md',
      presetScale: '2/3',
      dpr: '1',
    }).toString()}` || null;

  const { containerRef, loadedPhotoUrl } = useLazyLoadPhoto(photoUrl);

  const link = `${COSMOS_URL}/properties/${listing?.slug ?? '_'}/aid_${
    listing?.addressToken
  }?${new URLSearchParams({
    ...(utmSource && { utm_source: utmSource }),
    ...(utmMedium && { utm_medium: utmMedium }),
    ...(utmCampaign && { utm_campaign: utmCampaign }),
    ...(utmContent && { utm_content: utmContent }),
    ...(utmTerm && { utm_term: utmTerm }),
  }).toString()}`;

  return (
    <Box ref={containerRef}>
      <PropertyCard
        baths={listing?.baths}
        beds={listing?.beds}
        city={listing?.city}
        dwellingType={convertDwellingType(listing?.dwellingType)}
        estimatedMonthlyPayment={listing?.estimatedMonthlyPayment}
        href={link}
        isFavorite={isLiked}
        neighborhood={listing?.neighborhood}
        onFavoritePress={onClickReaction}
        photoUrl={loadedPhotoUrl}
        price={listing?.listPrice}
        sqft={listing?.livingSquareFootage}
      />
    </Box>
  );
};

const DISPLAYED_LISTING_SET_COUNT = 6;

export const SimilarListings: React.FC<SimilarListingsProps & Tracking> = ({
  listings,
  title,
  utmSource,
  utmMedium,
  utmCampaign,
  utmContent,
  utmTerm,
}) => {
  const [displayedListingCount, setDisplayedListingCount] = React.useState(
    DISPLAYED_LISTING_SET_COUNT,
  );

  const incrementDisplayedListingCount = () => {
    setDisplayedListingCount((prev) => prev + DISPLAYED_LISTING_SET_COUNT);
  };

  const similarListings = useMemo(() => {
    return listings
      .filter((_, index) => index < displayedListingCount)
      .map((listing, index) => {
        return (
          <Box
            key={index}
            mb={24}
            $largerThanXS={{ width: '100%' }}
            $largerThanSM={{ width: '100%' }}
            $largerThanMD={{ width: '48%' }}
            $largerThanLG={{ width: '48%' }}
          >
            <ListingCard
              listing={listing}
              utmSource={utmSource}
              utmMedium={utmMedium}
              utmCampaign={utmCampaign}
              utmContent={utmContent}
              utmTerm={utmTerm}
            />
          </Box>
        );
      });
  }, [listings, displayedListingCount]);

  return (
    <Box
      flexDirection="column"
      $smallerThanMD={{ paddingBottom: '$62x' }}
      $largerThanSM={{ paddingBottom: '$12x' }}
    >
      <Text tag="h5" typography="$subheaderXsmall" color="$contentPrimary" mb="$12x">
        {title || 'Similar listings'}
      </Text>
      <Box
        flexWrap="wrap"
        justifyContent="space-between"
        $largerThanXS={{ flexDirection: 'column' }}
        $largerThanSM={{ flexDirection: 'column' }}
        $largerThanMD={{ flexDirection: 'row' }}
        $largerThanLG={{ flexDirection: 'row' }}
      >
        {similarListings}
      </Box>
      {displayedListingCount < listings.length && (
        <Button
          onPress={incrementDisplayedListingCount}
          variant="secondary"
          size="small"
          analyticsName="marketplace-show-more-listings"
          label="See more homes"
        />
      )}
    </Box>
  );
};
