/* storybook-check-ignore */
import React from 'react';

import { Flex, Text, TextProps } from '@opendoor/bricks/core';

export interface NavigationBarProps {
  id?: string;
  title?: string;
  titleProps?: TextProps;
  subtitle?: string;
  rightComponent?: React.ReactNode;
  subtitleProps?: TextProps;
  leftComponent?: React.ReactNode;
}

export const getNavigationBarTitleId = (props: Pick<NavigationBarProps, 'id'>) => {
  return `${props.id || 'navigation-bar'}-title`;
};

export const NavigationBar: React.FC<NavigationBarProps> = ({
  id,
  title,
  titleProps,
  subtitle,
  rightComponent,
  subtitleProps,
  leftComponent,
}) => {
  const hasTitle = !!title;
  return (
    <Flex
      bgColor="neutrals0"
      py={['14px']}
      pl={['24px', null, '30px', '40px']}
      pr="18px"
      flex="1 1 auto"
      alignItems="center"
      justifyContent="flex-start"
      id={id}
      zIndex={2000}
    >
      {leftComponent && (
        <Flex
          justifySelf="flex-start"
          flexBasis={hasTitle ? '50%' : 'auto'}
          justifyContent="flex-start"
          alignItems="center"
          flexShrink={2}
          minWidth={hasTitle ? 'auto' : 0}
        >
          {leftComponent}
        </Flex>
      )}
      {title && (
        <Flex flexDirection="column" flexBasis="100%" flexShrink={1} minWidth={0}>
          <Text
            size="s1"
            fontWeight={['semibold']}
            // @ts-expect-error letter-spacing is now tokenized in bricks
            letterSpacing="-0.03em"
            whiteSpace="nowrap"
            as="h1"
            mb="0"
            id={getNavigationBarTitleId({ id })}
            textOverflow="ellipsis"
            overflow="hidden"
            {...titleProps}
          >
            {title}
          </Text>
          {subtitle && (
            <Text
              size="s2"
              fontWeight="regular"
              // @ts-expect-error letter-spacing is now tokenized in bricks
              letterSpacing="-0.03em"
              whiteSpace="nowrap"
              as="h2"
              mb="0"
              textOverflow="ellipsis"
              overflow="hidden"
              {...subtitleProps}
            >
              {subtitle}
            </Text>
          )}
        </Flex>
      )}

      <Flex
        justifySelf="flex-end"
        flexBasis={hasTitle ? '50%' : 'auto'}
        justifyContent="flex-end"
        alignItems="center"
        flexShrink={2}
        minWidth={hasTitle ? 'auto' : 0}
      >
        {rightComponent}
      </Flex>
    </Flex>
  );
};
