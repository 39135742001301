/* storybook-check-ignore */
import { Box } from '@opendoor/bricks-next';
import { Divider } from '@opendoor/bricks/core';
import dynamic from 'next/dynamic';

import { is3PExclusive } from 'components/marketplace/helpers';

import { PropertyDetailsType } from '../types';
import { ExclusiveCTAs } from './ExclusiveCTAs';

interface PropertyCTAsProps {
  property: PropertyDetailsType;
}

const BuyDirectCTA = dynamic(() => import('../BuyDirectCta').then((mod) => mod.BuyDirectCTA), {
  ssr: false,
});

export const PropertyCTAs = ({ property }: PropertyCTAsProps) => {
  const is3PE = is3PExclusive(property);

  if (!is3PE && property.displayBadge !== 'ON_THE_MARKET') {
    return null;
  }

  return is3PE ? (
    <ExclusiveCTAs property={property} />
  ) : (
    <Box tag="menu" px={0}>
      <BuyDirectCTA addressToken={property.addressToken} />
      <Divider mt={40} />
    </Box>
  );
};
