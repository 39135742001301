/* storybook-check-ignore */
import { Box, Link, Text } from '@opendoor/bricks-next';
import { formatCurrency } from '@opendoor/shared-fe/formatUtils';

import { PropertyDetailsFragment } from '__generated__/athena';

import { is3PExclusive } from 'components/marketplace/helpers';

import { PricingBuyItNow, PricingTraditional } from './Pricing';

interface PropertySummaryProps {
  property: PropertyDetailsFragment;
}

const PropertyPrice = ({ property }: PropertySummaryProps) => {
  if (is3PExclusive(property)) {
    return null;
  }

  return property?.displayBadge === 'TRADITIONAL' ? (
    <PricingTraditional listPrice={property?.listPrice} />
  ) : (
    <PricingBuyItNow buyDirectPrice={property?.buyDirectPrice} listPrice={property?.listPrice} />
  );
};

export const PropertySummary = ({ property }: PropertySummaryProps) => {
  const is3PE = is3PExclusive(property);
  const shouldShowZillowUrl = is3PE && property.listingState === 'AVAILABLE_SOON';

  return (
    <Box tag="hgroup">
      <Text tag="h1" typography="$subheaderLarge" pb={24}>
        <Text
          $largerThanSM={{
            typography: '$subheaderLarge',
          }}
          tag="span"
          typography="$subheaderSmall"
          fontWeight="$medium"
        >
          {property.street},
        </Text>
        <br />
        <Text
          $largerThanSM={{
            typography: '$subheaderLarge',
          }}
          tag="span"
          typography="$subheaderSmall"
          fontWeight="$medium"
          color="$contentTertiary"
        >
          {property.city}, {property.state} {property.zip}
        </Text>
      </Text>
      <Box tag="section">
        <PropertyPrice property={property} />
        <Box flexDirection="row" pb="$2x">
          <Text tag="span" typography="$bodyMedium" color="$contentTertiary">
            {property.bedrooms} bds
          </Text>
          <Text tag="span" typography="$bodyMedium" color="$contentTertiary" mx={8}>
            ·
          </Text>
          <Text tag="span" typography="$bodyMedium" color="$contentTertiary">
            {property.bathrooms} ba
          </Text>
          <Text tag="span" typography="$bodyMedium" color="$contentTertiary" mx={8}>
            ·
          </Text>
          <Text tag="span" typography="$bodyMedium" color="$contentTertiary">
            {property.sqFtTotalLiving} sqft
          </Text>
          {((is3PE && property.listingState !== 'IN_CONTRACT') || !is3PE) &&
            property?.estimatedMonthlyPayment && (
              <>
                <Text tag="span" typography="$bodyMedium" color="$contentTertiary" mx={8}>
                  ·
                </Text>
                <Text tag="p" typography="$bodyMedium" color="$contentTertiary">
                  {`${formatCurrency(property?.estimatedMonthlyPayment)}/month est.`}
                </Text>
              </>
            )}
        </Box>
        <Box flexDirection="row">
          <Text tag="span" typography="$bodyMedium" color="$contentTertiary">
            {!!property?.schoolDistricts?.length && `${property?.schoolDistricts?.join(' & ')}`}
          </Text>
        </Box>
        {shouldShowZillowUrl && property.zillowUrl && (
          <Box flexDirection="row" mt="$12x">
            <Link
              analyticsName="cosmos-core-pdp-zillow-photos"
              typography="$labelMedium"
              color="$contentPrimary"
              textDecorationLine="underline"
              href={property.zillowUrl}
            >
              View photos on Zillow
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};
