/* storybook-check-ignore */
import { Box, Pill } from '@opendoor/bricks-next';

import { Badge, PropertyDetailsFragment } from '__generated__/athena';

interface PropertyStatusProps {
  property: PropertyDetailsFragment;
}

export const DisplayStateStatusMap: Record<Badge, string | undefined> = {
  TEMP_UNAVAILABLE: 'Temporarily unavailable',
  AVAILABLE_SOON: 'Available soon',
  IN_CONTRACT: 'In contract',
  RECENTLY_SOLD: 'Sold',
  ON_THE_MARKET: undefined,
  IS_EXCLUSIVE: undefined,
  TRADITIONAL: undefined,
  UNKNOWN: undefined,
};

export const PropertyStatus = ({ property }: PropertyStatusProps) => {
  if (!property?.listingState || !DisplayStateStatusMap[property?.listingState]) return null;
  return (
    <Box mb="$12x">
      <Pill
        // empty string done for typescript, will never reach that case
        label={DisplayStateStatusMap[property?.listingState] ?? ''}
        rounded
        size="medium"
        $smallerThanMD={{ size: 'small' }}
        weight="regularWeight"
      />
    </Box>
  );
};
