/* storybook-check-ignore */
import { useCallback, useState } from 'react';

import { Box, CloseButton, Dialog, PressableBox, Text, Toast } from '@opendoor/bricks-next';
import { Icon } from '@opendoor/bricks/core';

import { PropertyDetailsFragment } from '__generated__/athena';

import useTrackProperty from 'components/marketplace/helpers/useTrackProperty';

import { useObservability } from '../../../helpers/observability';

function getShareableContent(slug: string, addressToken: string) {
  return `Check out this home for sale on Opendoor!\n\n${getShareableLink(slug, addressToken)}`;
}

function getShareableLink(slug: string, addressToken: string) {
  return `www.opendoor.com/properties/${slug}/aid_${addressToken}`;
}

function getEmailLink(slug: string, addressToken: string) {
  return `mailto:?body=${encodeURIComponent(getShareableContent(slug, addressToken))}`;
}

function getTextMessageLink(slug: string, addressToken: string) {
  return `sms:?&body=${encodeURIComponent(getShareableContent(slug, addressToken))}`;
}

const PRESS_SCALE = 0.98;
const TOAST_DURATION = 1000; // 1 second

export const ShareModal = ({
  property,
  isOpen,
  onClose,
}: {
  property: PropertyDetailsFragment;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const { trackProperty } = useTrackProperty();
  const { trackJourneyEvent } = useObservability();
  const copyToClipboard = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
    }
  };

  const handleOnShare = useCallback(() => {
    trackProperty('property-share', 'marketplace-pdp-copy');
    if (property.addressToken) {
      trackJourneyEvent('marketplaceProperty', 'share', {
        addressUuid: property.addressToken,
        source: 'copy',
      });
    }
    copyToClipboard(getShareableLink(property.slug, property.addressToken));
    setIsToastOpen(true);
    onClose();
  }, []);

  const handleSms = useCallback(() => {
    trackProperty('property-share', 'marketplace-pdp-sms');
    window.open(getTextMessageLink(property.slug, property.addressToken), '_self');
    trackJourneyEvent('marketplaceProperty', 'share', {
      addressUuid: property.addressToken,
      source: 'sms',
    });
  }, []);

  const handleEmail = useCallback(() => {
    trackProperty('property-share', 'marketplace-pdp-email');
    trackJourneyEvent('marketplaceProperty', 'share', {
      addressUuid: property.addressToken,
      source: 'email',
    });
    window.open(getEmailLink(property.slug, property.addressToken), '_self');
  }, []);

  const closeToast = useCallback(() => {
    setIsToastOpen(false);
  }, []);

  return (
    <>
      <Dialog
        isOpen={isOpen}
        dismissable
        analyticsName="marketplace-share-modal"
        trackImpression
        setIsOpen={onClose}
      >
        <Box $smallerThanMD={{ width: '100%' }} $largerThanSM={{ width: 300 }}>
          <Box flexDirection="column" px={30} py={24}>
            <Box flexDirection="row" justifyContent="space-between">
              <Text tag="p" typography="$subheaderSmall">
                Share
              </Text>
              <CloseButton
                analyticsName="close-share-modal"
                onPress={onClose}
                size="large"
                variant="default"
              />
            </Box>
            <Text tag="p" typography="$bodySmall" color="$contentSecondary" mt={4}>
              {property.street}
            </Text>
          </Box>
          <PressableBox
            flexDirection="row"
            justifyContent="space-between"
            onPress={handleOnShare}
            analyticsName="property-share-copy"
            aria-label="Copy to clipboard"
            alignItems="center"
            borderColor="$borderSecondary"
            borderBottomWidth={1}
            py={24}
            mx={32}
            pressStyle={{
              scale: PRESS_SCALE,
            }}
          >
            <Text tag="span" typography="$bodySmall">
              Copy
            </Text>
            <ContentCopyIcon size={16} />
          </PressableBox>
          <PressableBox
            flexDirection="row"
            justifyContent="space-between"
            onPress={handleSms}
            analyticsName="property-share-email"
            aria-label="Share by SMS"
            alignItems="center"
            borderColor="$borderSecondary"
            borderBottomWidth={1}
            py={24}
            mx={32}
            pressStyle={{
              scale: PRESS_SCALE,
            }}
          >
            <Text tag="span" typography="$bodySmall">
              SMS
            </Text>
            <Icon name="chat-inactive" size={16} />
          </PressableBox>
          <PressableBox
            flexDirection="row"
            justifyContent="space-between"
            onPress={handleEmail}
            analyticsName="property-share-email"
            aria-label="Share in Email"
            alignItems="center"
            py={24}
            mx={32}
            pressStyle={{
              scale: PRESS_SCALE,
            }}
          >
            <Text tag="span" typography="$bodySmall">
              Email
            </Text>
            <Icon name="email" size={16} />
          </PressableBox>
        </Box>
      </Dialog>
      <Toast
        analyticsName="copy-link-toast-success"
        duration={TOAST_DURATION}
        isOpen={isToastOpen}
        message="Link copied to clipboard"
        onClose={closeToast}
        isSuccess
      />
    </>
  );
};

function ContentCopyIcon({ size = 24 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_3070_193338" fill="white">
        <rect x="0.5" y="3.5" width="12.5" height="16.25" rx="1.25" />
      </mask>
      <rect
        x="0.5"
        y="3.5"
        width="12.5"
        height="16.25"
        rx="1.25"
        stroke="black"
        strokeWidth="3.75"
        mask="url(#path-1-inside-1_3070_193338)"
      />
      <path
        d="M4.25 1H14.25C14.9404 1 15.5 1.55964 15.5 2.25V16"
        stroke="black"
        strokeWidth="1.875"
      />
    </svg>
  );
}
