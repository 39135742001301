/* storybook-check-ignore */
import { FC } from 'react';

import { Dialog } from '@opendoor/bricks-next';

import { useCustomerToursContext } from '../context/customerToursContext';

interface CancelTourModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CancelTourModal: FC<CancelTourModalProps> = ({ isOpen, onClose }) => {
  const { tours, cancelTour, cancelling } = useCustomerToursContext();

  const tour = tours.find(
    (tour) =>
      tour.state === 'PENDING' ||
      tour.state === 'PENDING_VERIFICATION' ||
      tour.state === 'SCHEDULED',
  );

  const onCancelPress = async () => {
    if (tour) {
      await cancelTour(tour.id);
    }

    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={onClose}
      analyticsName="cosmos-exclusives-cancel-tour-modal"
      trackImpression
    >
      <Dialog.Content
        title="Are you sure?"
        description="If you cancel, you’ll need to request additional times to see this home."
      />
      <Dialog.Actions
        buttonOneProps={{
          variant: 'primary',
          analyticsName: 'cosmos-pdp-slide-cancel-tour-button',
          label: 'Cancel tour',
          loading: cancelling,
          onPress: onCancelPress,
        }}
      />
    </Dialog>
  );
};

export default CancelTourModal;
