/* storybook-check-ignore */
import { FC } from 'react';

import { Box, Button, FavoriteButton } from '@opendoor/bricks-next';

import ExclusiveBenefitsHeader from './ExclusiveBenefitsCTAHeader';

interface FloatingCTAProps {
  ctaText: string;
  onClickCta: () => void;
  isExclusive: boolean;
  isLiked?: boolean;
  onClickReaction?: () => void;
  variant?: 'secondary' | 'primary' | 'secondaryInverse';
}

export const FloatingCTA: FC<FloatingCTAProps> = ({
  ctaText,
  onClickCta,
  isExclusive,
  isLiked,
  onClickReaction,
  variant = 'primary',
}) => {
  return (
    <Box // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      position="sticky"
      zIndex={4}
      bottom={0}
      width="100%"
      $smallerThanMD={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        position: 'fixed',
        paddingHorizontal: '$6x',
        paddingBottom: '$6x',
      }}
      $largerThanSM={{
        paddingHorizontal: '$12x',
        paddingBottom: '$12x',
      }}
    >
      <ExclusiveBenefitsHeader isExclusive={isExclusive}>
        <Box
          backgroundColor="$backgroundPrimary"
          borderRadius="$12x"
          borderWidth={0.5}
          borderColor="$borderStateHoverTertiary"
          shadow="$pressed"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <FavoriteButton
            analyticsName="cosmos-marketplace-pdp-favorite"
            onPress={onClickReaction}
            size="medium"
            variant="default"
            ml={16}
            my={12}
            isFavorited={isLiked}
          />
          <Button
            analyticsName="cosmos-marketplace-footer-cta"
            aria-label={ctaText}
            alignSelf="center"
            variant={variant}
            size="small"
            onPress={onClickCta}
            label={ctaText}
            mr={12}
            my={12}
          />
        </Box>
      </ExclusiveBenefitsHeader>
    </Box>
  );
};
