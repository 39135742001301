import { DateObjectUnits, DurationLike } from 'luxon';

import { PropertyAccess } from './types';

export const DISPLAYED_CALENDAR_LENGTH: DurationLike = { weeks: 2 };
export const MAX_CART_SIZE = 3;

// Showings (3P tour requests)
export const SHOWING_SLOT_PERIOD: DurationLike = { hours: 1 };
export const FIRST_BOOKABLE_SHOWING_SLOT_BUFFER_TIME: DurationLike = { hours: 4 };
export const FINAL_SHOWING_CUTOFF_TIME: DateObjectUnits = { hour: 20, minute: 0 }; // 8pm

// Instant Self Tours (Homes with smart locks)
export const SELF_TOUR_SLOT_PERIOD: DurationLike = { minutes: 30 };
export const FIRST_BOOKABLE_SELF_TOUR_SLOT_BUFFER_TIME: DurationLike = { minutes: 30 };
export const FINAL_SELF_TOUR_CUTOFF_TIME: DateObjectUnits = { hour: 19, minute: 0 }; // 7pm

// Map
export const TimeSlotConstantsMap = {
  [PropertyAccess.ShowingRequired]: {
    slotPeriod: SHOWING_SLOT_PERIOD,
    firstBookableSlotBufferTime: FIRST_BOOKABLE_SHOWING_SLOT_BUFFER_TIME,
    finalTourCutoffTime: FINAL_SHOWING_CUTOFF_TIME,
  },
  [PropertyAccess.SelfTouring]: {
    slotPeriod: SELF_TOUR_SLOT_PERIOD,
    firstBookableSlotBufferTime: FIRST_BOOKABLE_SELF_TOUR_SLOT_BUFFER_TIME,
    finalTourCutoffTime: FINAL_SELF_TOUR_CUTOFF_TIME,
  },
};
